import * as cart from './../cart';
import { OrderLineStatus, OrderType, PreferenceGender, RateType, DecimalType, schema, BookingStatus, PaymentStatus, TenderType } from '../';
import { plainToInstance } from 'class-transformer';
import '@edge/common';
import { OrderShipping } from '../schema/order_shipping';

export class CartItem extends cart.CartItem {
  override meta?: GroupCart;
}

export class Cart extends cart.Cart {
  override items: CartItem[];
}

export class Guest {
  id?: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  isServiceSelected?: boolean;
  medicalNote?: string;
  serviceNote?: string;
  lockerNumber?: string;
  items?: TreatmentGuestCartItem[];
}

export class ProductOnlineMapCategory {
  name?: string;
  slug?: string;
}

export class ProductOnlineMapSequence {
  sequence?: number;
}

export class ProductOnlineMapSequenceWithCategory extends ProductOnlineMapSequence {
  CategoryOnline?: ProductOnlineMapCategory;
}

export class CategoryName {
  name?: string;
}

export class Categories {
  onlineProductCategories?: ProductOnlineMapCategory[];
  productCategory?: CategoryName;
}

export class PrimaryContact {
  fullName?: string;
  phoneNumber?: string;
}

export class TreatmentGuestCartItem {
  id: string;
  calendarId: string;
  customerId?: string;
  appointmentId?: string;
  parentProductId?: string;
  parentAppointmentServiceName?: string;
  addOnServiceId?: string;
  addOnServiceName?: string;
  serviceName?: string;
  serviceTime?: string;
  guestName?: string;
  preferenceGenderDescription?: string;
  preferenceGender?: PreferenceGender;
  therapistId?: string;
  therapistName?: string;
  therapistGender?: string;
  productId?: string;
  imageUrl?: string;
  price?: number;
  taxRate?: number;
  tipRate?: number;
  treatmentDuration?: number;
  isForCouples?: boolean;
  isGenderMatch?: boolean;
  createdAt?: string;
  serviceStartAt?: string;
  serviceEndAt?: string;
  serviceCheckInAt?: string;
  serviceCheckOutAt?: string;
  serviceDate?: string;
  isPrepaidOnly?: boolean;
  notesCount?: number;
  orderLineId?: string;
  categories?: Categories;
  groupKey?: string;
}

export class GroupCart {
  numberOfGuests?: number | string;
  numberOfItems?: number | string;
  guests?: Guest[];
  availableTimeSlot?: string;
  isReadyToCheckout?: boolean;
  isReadyToPlaceOrder?: boolean;
  subtotalAmount?: number;
  taxAmount?: number;
  tipAmount?: number;
  dueAtServiceAmount?: number;
  dueNowAmount?: number;
  isAgreed?: boolean;
  isMedicalNoteRequired?: boolean;
  expireIn?: number;
  expireAt?: Date;
  isRenewable?: boolean;
  serviceDate?: string;
  serviceDateUtc?: Date;
  calendarId?: string;
}

export class NoteDto {
  id: string;
  relationId: string;
  message: string;
  level: number;
  createdAt: string;
  createdByName: string;
}

export enum SocketEventType {
  HandleAppointment = 'handleAppointment',
  HandleTimeOff = 'handleTimeOff',
  UpdateLunch = 'updateLunch',
  DeleteLunch = 'deleteLunch',
  UpdateTherapistSchedule = 'updateTherapistSchedule',
}

export interface GuestWithCustomerId {
  preferenceGender: PreferenceGender;
  customerId: string;
}

export interface TreatmentMap {
  serviceId?: string;
  therapistId?: string;
  roomId?: string;
}

type OrderLinePaymentStatus = Pick<schema.CancelledOrderLines, 'feeAmount' | 'reason'> & { paymentStatus: PaymentStatus; tenderType: TenderType };
export class TreatmentOrderLinesDto extends schema.OrderLines {
  Product?: schema.Product;
  Appointment?: TreatmentOrderLineAppointment;
  Notes?: NoteDto[];
  notesCount?: number;
  orderLinePaymentStatus?: OrderLinePaymentStatus;
  meta?: OrderLineMeta;
  paymentStatus: PaymentStatus;
}

export class TreatmentOrderLineAppointment {
  id?: string;
  calendarId?: string;
  customerId?: string;
  serviceTime?: string;
  prevServiceTime?: string;
  serviceStartAt?: Date;
  serviceEndAt?: Date;
  prevServiceStartAt?: Date;
  prevServiceEndAt?: Date;
  serviceDate?: string;
  isForCouples?: boolean;
  therapistGender?: string;
  appointmentType?: number;
  imageUrl?: string;
  status?: number;
  appointmentId?: string;
  serviceId?: string;
  serviceName?: string;
  addOnServiceId?: string;
  addOnServiceName?: string;
  parentServiceId?: string;
  parentAppointmentServiceName?: string;
  customerName?: string;
  firstName?: string;
  lastName?: string;
  therapistId?: string;
  therapistName?: string;
  preferenceGenderDescription?: string;
  preferenceGender?: PreferenceGender;
  refKey?: string;
  meta?: any;
  groupKey?: string;
  roomName?: string;
  contact1Id?: string;
  contact2Id?: string;
  contact1Name?: string;
  contact1Phone?: string;
  contact1Email?: string;
  contact2Name?: string;
  contact2Phone?: string;
  contact2Email?: string;
  serviceCheckInAt?: Date | null;
  serviceCheckOutAt?: Date | null;
  createdAt?: Date;
  isServiceCancellationAvailable?: boolean;
  confirmedByUserAt?: Date;
  confirmedByGuestAt?: Date;
  linkKey?: string;
}

export interface Appointment {
  id: string;
  calendarId: string;
  appointmentId?: string;
  imageUrl: string;
  serviceName: string;
  serviceId: string;
  addOnServiceId?: string;
  addOnServiceName?: string;
  customerId?: string;
  therapistName?: string;
  therapistGender?: string;
  isGenderMatched?: boolean;
  guestName?: string;
  preferenceGenderDescription?: string;
  notes?: NoteDto[];
  notesCount?: number;
  status?: number;
  paymentStatus?: number;
  price?: number;
  discountAmount?: number;
  isServiceCancellationAvailable?: boolean;
  orderLineStatus?: OrderLineStatus;
  orderLinePaymentStatus?: OrderLinePaymentStatus;
  serviceCheckInAt?: Date | null;
  serviceCheckOutAt?: Date | null;
  meta?: OrderLineMeta;
  orderId?: string;
  children?: Appointment[];
}

export interface ServicesByTime {
  serviceDate: string;
  serviceTime: string;
  serviceStartAt: string;
  serviceEndAt: string;
  prevServiceTime?: string;
  appointments: Appointment[];
}

export type AggregatedProduct = {
  serviceId: string;
  serviceName: string;
  addOnServiceId?: string;
  addOnServiceName?: string;
  quantity: number;
  price?: string;
  totalPrice?: string;
  cancellationFee?: string;
  cancellationReason?: string;
  discountItemId?: string;
  discountItemName?: string;
  discountAmount?: string;
};

export type ItemByRate = {
  id: string;
  sellingPrice: number;
  taxAmount?: number;
  type: RateType;
  rate: number;
  isCheckedOut?: boolean;
  currentTaxAmount: number;
};

export type AggregatedItemsByRate = {
  key: number;
  items: ItemByRate[];
};

export type SummaryAmounts = {
  amount: number;
  tax1Amount: number;
  tax2Amount: number;
  tip1Amount: number;
  tip2Amount: number;
  totalAmount: number;
  refundAmount: number;
  discount1Amount: number;
  discount2Amount: number;
  discount3Amount: number;
  extraChargeAmount: number;
  shippingAmount: number;
  updatedOrderLines?: CalculateOrderSummaryAmountsArg[];
};

export type OrderLines = {
  id: string;
  orderId: string;
  sequence?: number;
  categoryId: string;
  productId: string;
  customerId?: string;
  orderShippingId?: string;
  salesRepresentativeId?: string;
  appointmentId?: string;
  listPrice: number;
  sellingPrice: number;
  tax1Amount: number;
  tax2Amount: number;
  extraChargeAmount: number;
  tip1Amount: number;
  tip2Amount: number;
  discount1Amount: number;
  discount2Amount: number;
  discount3Amount: number;
  promotionAmount: number;
  totalAmount: number;
  refundAmount: number;
  status: number;
  orderLineId?: string;
  description?: string;
  meta?: any;
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
  productType: number;
  productName?: string;
};

export type Rates = {
  id?: string;
  name?: string;
  taxCode?: string;
  rate?: number;
  type?: number;
  tipAmount?: number;
  discountAmount?: number;
  status?: number;
  isOverrideGiftCertificate?: boolean;
};

export type GiftCertificate = {
  id?: string;
  name?: string;
  servicePrice?: number;
  sellingPrice?: number;
  discountAmount?: number;
  tipAmount?: number;
  isCommission?: boolean;
  validUntil?: Date;
  capacity?: number;
  scheduledId?: string;
  legacyCode?: string;
  description?: string;
  status?: number;
};

export type RefundedOrderLines = {
  id: string;
  orderId: string;
  orderLineId: string;
  endOfDateId?: string;
  refundedAmount: number;
  reason?: string;
  meta?: any;
};

export type CancelledOrderLines = {
  id: string;
  orderId: string;
  orderLineId: string;
  endOfDateId?: string;
  feeAmount: number;
  reason?: string;
  meta?: any;
  isWaived: boolean;
};

export type OrderLineMeta = {
  rates?: Rates[];
  orderType?: OrderType;
  giftCertificate: GiftCertificate;
};

type OrderLinesArg = Pick<
  OrderLines,
  'id' | 'sellingPrice' | 'tax1Amount' | 'tax2Amount' | 'tip1Amount' | 'tip2Amount' | 'extraChargeAmount' | 'discount1Amount' | 'discount2Amount' | 'discount3Amount' | 'promotionAmount' | 'totalAmount' | 'refundAmount' | 'status' | 'meta'
> &
  Partial<{
    listPrice: number;
    productId: string;
    productName: string;
    categoryId: string;
  }> & {
    RefundedOrderLines?: RefundedOrderLines[];
    CancelledOrderLine?: CancelledOrderLines;
  };

type OrderLinesArgs = (OrderLines & { OrderShipping: OrderShipping }) | CheckoutOrderLine | CalculateOrderSummaryAmountsArg;
export type CalculateOrderSummaryAmountsArg = OrderLinesArg;
export type AggregateOrderLinesByRateArg = CheckoutOrderLine;

export type CheckOutAppointmentBase = Pick<
  TreatmentOrderLineAppointment,
  'id' | 'serviceTime' | 'isForCouples' | 'appointmentType' | 'status' | 'appointmentId' | 'serviceId' | 'serviceName' | 'parentAppointmentServiceName' | 'parentServiceId' | 'firstName' | 'lastName' | 'customerName' | 'therapistName' | 'meta'
> & { serviceCheckOutAt?: string };

export type BookingStatues = {
  confirmed?: boolean | Date;
  buildingCheckedIn?: boolean | Date;
  serviceCheckedIn?: boolean | Date;
  serviceCheckedOut?: boolean | Date;
};

export class AppointmentMeta {
  lockerNumber: string;
  customerId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phoneNumber: string;
  admissionName: string;
  checkInAt: string;
  orderFromType: number;
}

export type CheckOutAppointment = CheckOutAppointmentBase & {
  bookingStatus: BookingStatus;
  bookingStatues: BookingStatues;
  Notes: Partial<NoteDto>[];
};

export type CheckoutOrderLine = OrderLinesArg & {
  Appointment?: CheckOutAppointment;
};

export type CancellationReason = {
  name: string;
  rule?: {
    type: DecimalType;
    rate: number;
    canBeWaive: boolean;
  };
};

export enum RelatedAppointmentFields {
  Couples = 1 << 0, // 1
  Order = 1 << 1, // 2
  GroupKey = 1 << 2, // 4
  LinkKey = 1 << 3, // 8
  All = (1 << 0) | (1 << 1) | (1 << 2) | (1 << 3), // 15
}

export const getServicesByTime = <T extends TreatmentGuestCartItem | TreatmentOrderLinesDto>(items: T[]): ServicesByTime[] => {
  if (!items || items?.length === 0) return [];
  const getSingleAppointmentDataByAppointment = <T>(item: T): Appointment => {
    if (item instanceof TreatmentOrderLinesDto) {
      const orderLine = plainToInstance(TreatmentOrderLinesDto, item);
      const appointment = orderLine.Appointment;
      return {
        id: appointment?.id,
        calendarId: appointment?.calendarId,
        appointmentId: appointment?.appointmentId,
        imageUrl: appointment?.imageUrl,
        serviceId: appointment.serviceId,
        serviceName: appointment?.addOnServiceId ? (appointment.appointmentId ? orderLine.productName : appointment.addOnServiceName) : orderLine.productName,
        addOnServiceId: appointment?.addOnServiceId,
        addOnServiceName: appointment?.addOnServiceName,
        therapistName: appointment?.therapistName,
        therapistGender: appointment?.therapistGender,
        preferenceGenderDescription: appointment.preferenceGenderDescription,
        guestName: appointment?.customerName,
        status: appointment?.status,
        orderLineStatus: orderLine.status,
        orderLinePaymentStatus: orderLine.orderLinePaymentStatus,
        notesCount: orderLine.notesCount,
        price: orderLine.sellingPrice,
        discountAmount: orderLine.discount1Amount,
        meta: orderLine.meta,
        notes: orderLine.Notes,
        customerId: appointment?.customerId,
        serviceCheckInAt: appointment?.serviceCheckInAt,
        serviceCheckOutAt: appointment?.serviceCheckOutAt,
        isServiceCancellationAvailable: appointment.isServiceCancellationAvailable,
        orderId: orderLine.orderId,
      };
    } else if (item instanceof TreatmentGuestCartItem) {
      const cartItem = plainToInstance(TreatmentGuestCartItem, item);
      return {
        id: cartItem.id,
        calendarId: cartItem.calendarId,
        appointmentId: cartItem.appointmentId,
        imageUrl: cartItem.imageUrl,
        serviceId: cartItem.productId,
        serviceName: cartItem.serviceName,
        addOnServiceId: cartItem?.addOnServiceId,
        addOnServiceName: cartItem?.addOnServiceName,
        therapistName: cartItem.therapistName,
        guestName: cartItem.guestName,
        preferenceGenderDescription: cartItem.preferenceGenderDescription,
        price: cartItem.price,
        therapistGender: cartItem.therapistGender,
        notesCount: cartItem.notesCount,
        isGenderMatched: cartItem.isGenderMatch,
        serviceCheckInAt: cartItem.serviceCheckInAt?.toDate(),
        serviceCheckOutAt: cartItem.serviceCheckOutAt?.toDate(),
      };
    }
  };
  const getGroupAppointmentDataByAppointment = (item: any): Appointment => {
    if (item instanceof TreatmentOrderLinesDto) {
      const appointment = item.Appointment;
      const groupAppointmentData: Appointment = {
        id: appointment.id,
        calendarId: appointment.calendarId,
        appointmentId: appointment.appointmentId,
        serviceName: appointment.addOnServiceName ?? appointment.parentAppointmentServiceName,
        addOnServiceId: appointment.addOnServiceId,
        addOnServiceName: appointment.addOnServiceName,
        orderLinePaymentStatus: item.orderLinePaymentStatus,
        serviceId: appointment.parentServiceId,
        status: appointment.status,
        orderLineStatus: item.status,
        paymentStatus: item.paymentStatus,
        imageUrl: appointment.imageUrl,
        serviceCheckOutAt: appointment.serviceCheckOutAt,
        isServiceCancellationAvailable: appointment.isServiceCancellationAvailable,
        children: [getSingleAppointmentDataByAppointment(item)],
      };
      return groupAppointmentData;
    } else if (item instanceof TreatmentGuestCartItem) {
      return {
        id: item.id,
        calendarId: item.calendarId,
        appointmentId: item.appointmentId,
        serviceId: item.parentProductId,
        serviceName: item.parentAppointmentServiceName,
        addOnServiceId: item.addOnServiceId,
        addOnServiceName: item.addOnServiceName,
        imageUrl: item.imageUrl,
        children: [getSingleAppointmentDataByAppointment(item)],
      };
    }
  };
  const getServiceData = <T>(item: T) => {
    if (item instanceof TreatmentOrderLinesDto) {
      return {
        serviceStartAt: typeof item.Appointment.serviceStartAt == 'string' ? item.Appointment.serviceStartAt : item.Appointment.serviceStartAt ? item.Appointment.serviceStartAt.toJSON() : '-',
        serviceEndAt: typeof item.Appointment.serviceEndAt == 'string' ? item.Appointment.serviceEndAt : item.Appointment.serviceEndAt ? item.Appointment.serviceEndAt.toJSON() : '-',
        serviceTime: item.Appointment.serviceTime,
        prevServiceTime: item.Appointment?.prevServiceTime,
        serviceDate: item.Appointment.serviceDate,
        appointmentId: item.Appointment.appointmentId,
      };
    } else if (item instanceof TreatmentGuestCartItem) return { serviceStartAt: item.serviceStartAt, serviceEndAt: item.serviceEndAt, serviceTime: item.serviceTime, serviceDate: item.serviceDate, appointmentId: item.appointmentId };
    return null;
  };
  const servicesByTime: ServicesByTime[] = [];
  for (const item of items) {
    const { serviceTime, serviceDate, serviceStartAt, serviceEndAt, appointmentId, prevServiceTime } = getServiceData(item);
    const index = servicesByTime.findIndex(el => el.serviceStartAt === serviceStartAt && el.serviceEndAt === serviceEndAt);
    if (index < 0)
      servicesByTime.push({
        serviceTime: serviceTime,
        prevServiceTime: prevServiceTime,
        serviceDate: serviceDate,
        serviceStartAt: serviceStartAt,
        serviceEndAt: serviceEndAt,
        appointments: [appointmentId ? getGroupAppointmentDataByAppointment(item) : getSingleAppointmentDataByAppointment(item)],
      });
    else {
      const target = servicesByTime[index];
      if (appointmentId) {
        const appIndex = target.appointments.findIndex(el => el.appointmentId == appointmentId);
        if (appIndex < 0) target.appointments.push(getGroupAppointmentDataByAppointment(item));
        else target.appointments[appIndex].children.push(getSingleAppointmentDataByAppointment(item));
      } else target.appointments.push(getSingleAppointmentDataByAppointment(item));
    }
  }

  return servicesByTime.sort((a, b) => {
    const aStartAt = a.serviceStartAt && a.serviceStartAt !== '-' ? a.serviceStartAt.toDayJs()?.toJSON() : null;
    const bStartAt = b.serviceStartAt && b.serviceStartAt !== '-' ? b.serviceStartAt.toDayJs()?.toJSON() : null;

    if (!aStartAt && !bStartAt) return 0;
    if (!aStartAt) return 1;
    if (!bStartAt) return -1;
    return aStartAt.localeCompare(bStartAt);
  });
};

export const getServicesByTimeWithAppointmentIds = <T extends TreatmentGuestCartItem | TreatmentOrderLinesDto>(items: T[], appointmentIds: string[]) => {
  return getServicesByTime(items).map(appointmentsByTime => {
    return { ...appointmentsByTime, appointments: appointmentsByTime.appointments.filter(appointment => appointmentIds.includes(appointment.id)) };
  });
};

export const generateGroupName = (customerProfile: PrimaryContact): string => {
  const fullName: string = customerProfile.fullName.replace(/\s/g, '');
  const last4Digits: string = customerProfile.phoneNumber.slice(-4);
  const groupName: string = `${fullName}_${last4Digits}`;
  return groupName;
};
const removeDollarSignAndComma = (value: string) => value.replace(/[$,]+/g, '');
export const aggregateProducts = (appointments: Appointment[]): AggregatedProduct[] => {
  const aggregatedProducts: AggregatedProduct[] = [];
  const isSameCancel = (item: AggregatedProduct, appointment: Appointment) => {
    return appointment.orderLinePaymentStatus?.reason == item?.cancellationReason && (appointment.orderLinePaymentStatus?.feeAmount ?? 0) == (item?.cancellationFee ? +removeDollarSignAndComma(item?.cancellationFee) ?? 0 : 0).toCurrency();
  };

  const isSameDiscount = (item: AggregatedProduct, appointment: Appointment) => {
    const giftCertificateId = appointment.meta?.giftCertificate?.id;
    return giftCertificateId == item.discountItemId && appointment.meta?.giftCertificate?.discountAmount == (item?.discountAmount ? +removeDollarSignAndComma(item?.discountAmount) ?? 0 : 0).toCurrency();
  };
  appointments.forEach(appointment => {
    const addToAggregatedProducts = (appointment: Appointment) => {
      if (appointment?.orderLineStatus == OrderLineStatus.Cancelled && !appointment.orderLinePaymentStatus?.paymentStatus) return;
      const index = aggregatedProducts.findIndex(item => {
        if (appointment?.meta?.giftCertificate?.id) return item.serviceId == appointment.serviceId && item.addOnServiceId == appointment.addOnServiceId && isSameDiscount(item, appointment) && isSameCancel(item, appointment);
        return item.serviceId === appointment.serviceId && item.addOnServiceId == appointment.addOnServiceId && isSameCancel(item, appointment);
      });
      if (index < 0) {
        if (appointment?.appointmentId && appointment?.children?.length > 0) {
          let price = 0;
          let discountAmount = 0;
          let cancellationFeeAmount = 0;

          appointment.children.forEach(childAppointment => (price += childAppointment.price));
          appointment.children.forEach(childAppointment => (discountAmount += childAppointment.discountAmount));
          appointment.children.forEach(childAppointment => (cancellationFeeAmount += childAppointment.orderLinePaymentStatus?.feeAmount ?? 0));

          aggregatedProducts.push({
            serviceId: appointment.serviceId,
            serviceName: appointment.serviceName,
            addOnServiceId: appointment.addOnServiceId,
            addOnServiceName: appointment.addOnServiceName,
            quantity: 1,
            price: price.toPriceString(),
            totalPrice: (price - discountAmount)?.toPriceString(),
            cancellationFee: cancellationFeeAmount?.toPriceString(), //appointment.orderLinePaymentStatus?.feeAmount?.toPriceString(),
            cancellationReason: appointment.orderLinePaymentStatus?.reason,
            discountAmount: appointment.discountAmount?.toPriceString(),
            discountItemId: appointment?.meta?.giftCertificate?.id,
            discountItemName: appointment?.meta?.giftCertificate?.name,
          });
        } else {
          aggregatedProducts.push({
            serviceId: appointment.serviceId,
            serviceName: appointment.serviceName,
            addOnServiceId: appointment.addOnServiceId,
            addOnServiceName: appointment.addOnServiceName,
            quantity: 1,
            price: appointment.price?.toPriceString(),
            cancellationFee: appointment.orderLinePaymentStatus?.feeAmount?.toPriceString(),
            cancellationReason: appointment.orderLinePaymentStatus?.reason,
            totalPrice: (appointment.price - appointment.discountAmount)?.toPriceString(),
            discountAmount: appointment.discountAmount?.toPriceString(),
            discountItemId: appointment?.meta?.giftCertificate?.id,
            discountItemName: appointment?.meta?.giftCertificate?.name,
          });
        }
      } else {
        aggregatedProducts[index].quantity++;
      }
    };
    if (appointment.appointmentId && (appointment?.meta?.giftCertificate?.id || appointment.children?.some(child => child?.meta?.giftCertificate?.id))) {
      if (appointment.children.every(child => child.meta?.giftCertificate?.id == appointment.children[0].meta?.giftCertificate?.id))
        addToAggregatedProducts({ ...appointment, meta: appointment.children[0].meta, discountAmount: appointment.children.reduce((a, b) => (a += b.discountAmount), 0) });
      else
        appointment.children.map(child => {
          addToAggregatedProducts(child);
        });
    } else addToAggregatedProducts(appointment);
  });
  return aggregatedProducts;
};

export const aggregateAppointmentsByReasonAndFee = (appointments: Appointment[], isRefunded: boolean = false): { reason: string; feeAmount: number; amount: string; quantity: number; appointments: Appointment[] }[] => {
  const aggregatedAppointments: { reason: string; feeAmount: number; amount: string; quantity: number; paymentStatus: PaymentStatus; appointments: Appointment[] }[] = [];

  const reasonMap: { [key: string]: { refunded: string; notRefunded: string } } = {
    'Early Cancel': { refunded: 'Early Cancellation Fee Refund', notRefunded: 'Early Cancellation Fee' },
    Reschedule: { refunded: 'Reschedule Fee Refund', notRefunded: 'Reschedule Fee' },
    'Late Cancel': { refunded: 'Late Cancellation Fee Refund', notRefunded: 'Late Cancellation Fee' },
    'No Show': { refunded: 'No Show Fee Refund', notRefunded: 'No Show Fee' },
    'Couples Upgrade': { refunded: 'Couples Upgrade Fee Refund', notRefunded: 'Couples Upgrade Fee' },
  };

  appointments.forEach(appointment => {
    if (appointment.orderLinePaymentStatus) {
      const { reason, feeAmount, paymentStatus } = appointment.orderLinePaymentStatus;
      const key = `${reason}-${feeAmount}`;
      const mappedReason = isRefunded ? reasonMap[reason]?.refunded : reasonMap[reason]?.notRefunded;
      const amount = !isRefunded && reason !== 'Early Cancel' && feeAmount === 0 && paymentStatus === PaymentStatus.Waived ? 'Waived' : (isRefunded ? '-$' : '$') + feeAmount.fromCurrency().toFixed(2);

      if (!aggregatedAppointments[key]) {
        aggregatedAppointments[key] = {
          reason: mappedReason,
          feeAmount,
          amount,
          quantity: 0,
          paymentStatus,
          appointments: [],
        };
      }

      aggregatedAppointments[key].quantity++;
      aggregatedAppointments[key].appointments.push(appointment);
    }
  });

  return Object.values(aggregatedAppointments);
};

export const aggregateOrderLinesByRate = <T1 extends AggregateOrderLinesByRateArg[]>(orderLines: T1): AggregatedItemsByRate[] => {
  const groupedOrderLines = orderLines
    .flatMap(orderLine => {
      orderLine.Appointment;
      const meta = orderLine?.meta;
      const metaRates = meta?.rates as Rates[];
      const giftCertificate = meta?.giftCertificate;
      const isCoupleGiftCertificate = giftCertificate?.capacity > 1;
      const sellingPrice =
        orderLine.sellingPrice -
        (giftCertificate
          ? isCoupleGiftCertificate
            ? metaRates
                .filter(el => el.type === RateType.Tip)
                .map(el => el.discountAmount ?? 0)
                .reduce((a, b) => a + b) || giftCertificate?.discountAmount
            : giftCertificate?.discountAmount
          : 0);
      if (metaRates) {
        return (orderLine.meta as any).rates
          .filter((rate: Rates) => rate.type !== RateType.Tip)
          .map((rate: Rates) => ({
            id: orderLine.id,
            sellingPrice: sellingPrice ?? 0,
            type: rate.type,
            rate: rate.rate,
            currentTaxAmount: orderLine.tax1Amount,
            isCheckedOut: Boolean(orderLine?.Appointment?.serviceCheckOutAt),
          }));
      } else {
        return [
          {
            id: orderLine.id,
            sellingPrice: sellingPrice ?? 0,
            type: 0,
            rate: 0,
            currentTaxAmount: orderLine.tax1Amount,
            isCheckedOut: Boolean(orderLine?.Appointment?.serviceCheckOutAt),
          },
        ];
      }
    })
    .groupBy('rate');

  return groupedOrderLines;
};

export const calculateTaxAmountsByRateGroup = <
  T extends {
    id: string;
    sellingPrice: number;
    tax1Amount: number;
    tax2Amount: number;
    tip1Amount: number;
    tip2Amount: number;
    extraChargeAmount: number;
    discount1Amount: number;
    discount2Amount: number;
    discount3Amount: number;
    promotionAmount: number;
    totalAmount: number;
    refundAmount: number;
    status: number;
    meta?: any;
    listPrice?: number;
    productId?: string;
    productName?: string;
    categoryId?: string;
    Appointment?: CheckOutAppointment;
    OrderShipping?: OrderShipping;
  },
>(
  orderLines: T[],
): {
  totalTaxByRateGroup: { rate: number; taxAmount?: number; tipAmount?: number }[];
  calculatedOrderLines: T[];
} => {
  const aggregatedOrderLinesByRate = aggregateOrderLinesByRate(orderLines);
  const totalTaxByRateGroup = [];
  const calculatedOrderLines = orderLines.map(el => ({ ...el }));
  const updatedOrderLinesMap = new Map(calculatedOrderLines.map(el => [el.id, el]));
  aggregatedOrderLinesByRate.forEach(rateGroup => {
    const rate = rateGroup.key;
    const type = rateGroup.items[0]?.type;

    switch (type) {
      case RateType.Tax:
        {
          // Step 1: Calculate the total selling price for all items in the current tax rate group.
          const totalSellingPrice = rateGroup.items.reduce((sum, item) => sum + item.sellingPrice, 0);

          // Step 2: Calculate the tax amount for the entire group based on the total selling price.
          // The tax amount is rounded to two decimal places.
          const calculatedAmountRoundedToTwoDecimalPlaces = calculateRateAmount(totalSellingPrice, rate);

          // Step 3: Initialize an object to store the distributed tax amounts per item and a variable to track the total rounded tax amount.
          const distributedTaxAmounts: { [key: string]: number } = {};
          let totalRoundedTaxAmount = 0;

          // Step 4: Distribute the calculated tax amount proportionally among the items based on their selling price.
          rateGroup.items.forEach(item => {
            // Calculate the proportion of the item's selling price relative to the total selling price.
            const proportion = item.sellingPrice / totalSellingPrice;

            // Calculate the tax amount for the item based on its proportion and round up.
            const itemTaxAmountByProportion = Math.round(calculatedAmountRoundedToTwoDecimalPlaces * proportion);

            // Store the calculated tax amount in the distributedTaxAmounts object.
            distributedTaxAmounts[item.id] = itemTaxAmountByProportion;

            // Accumulate the rounded tax amounts to keep track of the total.
            totalRoundedTaxAmount += itemTaxAmountByProportion;
          });

          // Step 5: Filter items to include only active ones (not checked out).
          // If no active items are found, use all items in the rate group.
          const activeItems = rateGroup.items.filter((el: ItemByRate) => !el.isCheckedOut);
          const itemsForDistribution = activeItems && activeItems.length > 0 ? activeItems : rateGroup.items;

          // Step 6: Sort the items by selling price in descending order.
          // In case of a tie in selling price, sort by item ID in ascending order.
          const sortedItems = itemsForDistribution.slice().sort((a, b) => {
            if (b.sellingPrice === a.sellingPrice) return a.id.localeCompare(b.id);
            return b.sellingPrice - a.sellingPrice;
          });

          // Step 7: Calculate the remainder that needs to be adjusted after distributing the tax.
          // The remainder is the difference between the rounded calculated tax amount and the sum of distributed tax amounts.
          let remainder = calculatedAmountRoundedToTwoDecimalPlaces - totalRoundedTaxAmount;

          const shouldAdjustRemainder = (remainder: number, itemRemainder: number) => {
            return (remainder < 0 && itemRemainder < 0) || (remainder > 0 && itemRemainder > 0);
          };

          const updateRemainder = (remainder: number, itemRemainder: number) => {
            return (remainder += remainder > 0 ? -itemRemainder : -itemRemainder);
          };

          // Step 8: Adjust the distributed tax amounts for items that are already checked out.
          rateGroup.items.forEach(item => {
            if (item.isCheckedOut) {
              if (remainder === 0) return; // Stop if the remainder has been fully distributed among checkedOut.
              // Calculate the difference between the current tax amount and the distributed tax amount.
              const itemRemainder = item.currentTaxAmount - distributedTaxAmounts[item.id] || (remainder > 0 ? 0 : 1);

              // Update the distributed tax amount to the current tax amount.
              distributedTaxAmounts[item.id] = item.currentTaxAmount;

              // Adjust the remainder
              if (shouldAdjustRemainder(remainder, itemRemainder)) {
                remainder = updateRemainder(remainder, itemRemainder);
              }
            }
          });

          // Step 9: Distribute the remainder across the sorted items.
          // If the remainder is positive, increment the tax amount by 1; if negative, decrement it by 1.
          for (const item of sortedItems) {
            if (remainder === 0) break; // Stop if the remainder has been fully distributed.
            distributedTaxAmounts[item.id] += remainder > 0 ? 1 : -1;
            remainder += remainder > 0 ? -1 : 1; // Adjust the remainder accordingly.
          }

          // Step 10: Update each item's tax amount with the final distributed tax amount.
          rateGroup.items.forEach(item => {
            const itemTaxAmount = distributedTaxAmounts[item.id] || 0;
            item.taxAmount = itemTaxAmount;

            // If the item exists in the updatedOrderLinesMap, update its tax amount.
            const updateItem = updatedOrderLinesMap.get(item.id);
            if (updateItem) {
              updateItem.tax1Amount = itemTaxAmount;
            }
          });

          // Step 11: Add the tax amount for the entire rate group to the totalTaxByRateGroup array.
          totalTaxByRateGroup.push({ rate, ...{ taxAmount: rateGroup.items.reduce((sum, item) => sum + item.taxAmount, 0) } });
        }
        break;

      default:
        break;
    }
  });

  return { totalTaxByRateGroup, calculatedOrderLines };
};

export const calculateOrderSummaryAmounts = (orderLines: CalculateOrderSummaryAmountsArg[]): SummaryAmounts => {
  const activeOrderLines = addTipRateProperties(orderLines.filter(el => el.status === OrderLineStatus.Active || el.status === OrderLineStatus.Processing || el.status === OrderLineStatus.Failed).map(el => el));
  const summaryAmounts: SummaryAmounts = { amount: 0, tax1Amount: 0, tax2Amount: 0, tip1Amount: 0, tip2Amount: 0, totalAmount: 0, refundAmount: 0, discount1Amount: 0, discount2Amount: 0, discount3Amount: 0, extraChargeAmount: 0, shippingAmount: 0 };
  if (activeOrderLines.length === 0) return summaryAmounts;
  const { totalTaxByRateGroup, calculatedOrderLines } = calculateTaxAmountsByRateGroup(activeOrderLines);
  const updatedOrderLines = applyDiscountAndTipAmountsToTotalAmount(calculatedOrderLines);
  const taxAmounts = totalTaxByRateGroup.filter(el => el.taxAmount);

  summaryAmounts.amount = updatedOrderLines.map(el => el.sellingPrice ?? 0).reduce((a, b) => a + b);
  summaryAmounts.tax1Amount = taxAmounts[0]?.taxAmount ?? 0;
  summaryAmounts.tax2Amount = (taxAmounts[1]?.taxAmount ?? 0) + (taxAmounts[2]?.taxAmount ?? 0);
  summaryAmounts.tip1Amount = updatedOrderLines.map(el => el.tip1Amount ?? 0).reduce((a, b) => a + b);
  summaryAmounts.tip2Amount = updatedOrderLines.map(el => el.tip2Amount ?? 0).reduce((a, b) => a + b);
  // summaryAmounts.shippingAmount = updatedOrderLines.map(el => el.shippingAmount ?? 0).reduce((a, b) => a + b);
  summaryAmounts.refundAmount = updatedOrderLines.map(el => el.refundAmount ?? 0).reduce((a, b) => a + b); //todo
  summaryAmounts.discount1Amount = updatedOrderLines.map(el => el.discount1Amount ?? 0).reduce((a, b) => a + b);
  summaryAmounts.discount2Amount = updatedOrderLines.map(el => el.discount2Amount ?? 0).reduce((a, b) => a + b);
  summaryAmounts.discount3Amount = updatedOrderLines.map(el => el.discount3Amount ?? 0).reduce((a, b) => a + b);
  summaryAmounts.extraChargeAmount = updatedOrderLines.map(el => el.extraChargeAmount ?? 0).reduce((a, b) => a + b); // todo
  summaryAmounts.totalAmount =
    summaryAmounts.amount + summaryAmounts.tax1Amount + summaryAmounts.tax2Amount + summaryAmounts.tip1Amount + summaryAmounts.tip2Amount - summaryAmounts.discount1Amount - summaryAmounts.discount2Amount - summaryAmounts.discount3Amount;
  return { ...summaryAmounts, updatedOrderLines: updatedOrderLines };
};

export const calculateCheckoutOrderLines = (orderLines: CheckoutOrderLine[]): CheckoutOrderLine[] => {
  return orderLines.map(orderLine => {
    const meta = updateMetaProperties(orderLine);
    const taxRates = meta.rates.filter(rate => rate.type === RateType.Tax).map(rate => rate.rate);
    const tax1Rate = taxRates[0] ?? 0;
    const tax2Rate = taxRates[1] ?? 0;
    const tip = meta.rates.filter(rate => rate.type === RateType.Tip);
    const discountAmount1 = tip[0]?.discountAmount ?? 0;
    const discountAmount2 = tip[1]?.discountAmount ?? 0;
    const tip1Amount = tip[0]?.tipAmount ?? 0;
    const tip2Amount = tip[1]?.tipAmount ?? 0;
    const tax1Amount = calculateRateAmount(orderLine.sellingPrice - discountAmount1 - discountAmount2 ?? 0, tax1Rate);
    const tax2Amount = calculateRateAmount(orderLine.sellingPrice - discountAmount1 - discountAmount2 ?? 0, tax2Rate);

    const totalAmount = (orderLine.sellingPrice ?? 0) + tax1Amount + tax2Amount + tip1Amount + tip2Amount;
    return {
      ...orderLine,
      meta: {
        ...meta,
      },
      discount1Amount: tip[0]?.discountAmount ?? 0,
      discount2Amount: tip[1]?.discountAmount ?? 0,
      tax1Amount,
      tax2Amount,
      tip1Amount,
      tip2Amount,
      totalAmount,
    };
  });
};

export const addTipRateProperties = <T1 extends OrderLinesArgs[]>(orderLines: T1): T1 => {
  if (orderLines.length === 0) {
    return orderLines;
  }

  return orderLines.map((orderLine: OrderLinesArg) => {
    const meta = updateMetaProperties(orderLine);
    return {
      ...(orderLine as CheckoutOrderLine),
      meta: {
        ...meta,
      },
    };
  }) as T1;
};

export const updateMetaProperties = (orderLine: OrderLinesArgs): OrderLineMeta => {
  const giftCertificate = orderLine.meta?.giftCertificate;
  const isCoupleGiftCertificate = giftCertificate?.capacity > 1;

  const updatedRates =
    orderLine.meta?.rates?.map((el: Rates) => {
      const sellingPrice = orderLine.sellingPrice - (giftCertificate ? (isCoupleGiftCertificate ? el.discountAmount ?? 0 : giftCertificate?.discountAmount) : 0);
      if (el.type === RateType.Tip) {
        return {
          ...el,
          rate: el?.rate ?? 0,
          tipAmount: (el?.rate && el.rate !== 0) || el?.isOverrideGiftCertificate ? calculateRateAmount(sellingPrice, el.rate) : giftCertificate ? (isCoupleGiftCertificate ? el?.tipAmount : giftCertificate.tipAmount) : el?.tipAmount ?? 0,
          discountAmount: giftCertificate ? (isCoupleGiftCertificate ? el?.discountAmount : giftCertificate?.discountAmount) : 0,
        };
      } else {
        return el;
      }
    }) ?? [];

  return {
    ...orderLine.meta,
    ...(updatedRates.length > 0 ? { rates: updatedRates } : {}),
  };
};

export const getCancellationFeeByCancellationReason = (cancelReason: CancellationReason, totalPrice: number, numberOfService: number = 1): number => {
  switch (cancelReason?.rule?.type) {
    case DecimalType.Fixed:
      return cancelReason.rule.rate.fromCurrency() * numberOfService;
    case DecimalType.Percent:
      return totalPrice * (cancelReason.rule.rate / 10000);
    default:
      return 0;
  }
};

export const calculateRateAmount = (sellingPrice: number, rate: number): number => {
  return Math.round((((sellingPrice ?? 0) * rate) / 10000000) * 100);
};
export const applyDiscountAndTipAmountsToTotalAmount = <
  T extends { meta?: any; discount1Amount?: number; discount2Amount?: number; discount3Amount?: number; tax1Amount: number; tax2Amount: number; tip1Amount?: number; tip2Amount?: number; totalAmount?: number; sellingPrice: number },
>(
  orderLines: T[],
): T[] => {
  return orderLines.map(orderLine => {
    const updatedOrderLine = { ...orderLine };
    if (updatedOrderLine.meta && updatedOrderLine.meta.rates) {
      const discountAmounts = [];
      const tipAmounts = [];
      updatedOrderLine.meta.rates.forEach((rate: Rates) => {
        if (rate.type === RateType.Tip && rate.discountAmount !== undefined) {
          discountAmounts.push(rate.discountAmount);
        }
        if (rate.type === RateType.Tip && rate.tipAmount !== undefined) {
          tipAmounts.push(rate.tipAmount);
        }
      });
      if (discountAmounts.length > 0) updatedOrderLine.discount1Amount = discountAmounts[0] || 0;
      if (discountAmounts.length > 1) updatedOrderLine.discount2Amount = discountAmounts[1] || 0;
      if (discountAmounts.length > 2) updatedOrderLine.discount3Amount = discountAmounts[2] || 0;
      if (tipAmounts.length > 0) updatedOrderLine.tip1Amount = tipAmounts[0] || 0;
      if (tipAmounts.length > 1) updatedOrderLine.tip2Amount = tipAmounts[1] || 0;
    }
    return {
      ...updatedOrderLine,
      totalAmount:
        updatedOrderLine.sellingPrice +
        (updatedOrderLine.tax1Amount ?? 0) +
        (updatedOrderLine.tax2Amount ?? 0) +
        (updatedOrderLine.tip1Amount ?? 0) +
        (updatedOrderLine.tip2Amount ?? 0) -
        (updatedOrderLine.discount1Amount ?? 0) -
        (updatedOrderLine.discount2Amount ?? 0) -
        (updatedOrderLine.discount3Amount ?? 0),
    };
  });
};
