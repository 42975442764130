export class GiftCertificate {
  id: string;

  name: string;

  servicePrice: number;

  sellingPrice: number;

  discountAmount: number;

  tipAmount: number;

  isCommission: boolean;

  validUntil: Date;

  capacity: number;

  scheduledId?: string;

  legacyCode: string;

  description?: string;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
