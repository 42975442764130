export class Ticket {
  id: string;

  reservationId: string;

  orderLineId?: string;

  barcode?: string;

  status: number;

  refs?: string;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
