export class Setting {
  id: string;

  key: string;

  value: string;

  type: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
