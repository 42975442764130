export class ProductService {
  id: string;

  serviceType: number;

  sequence?: number;

  capacity: number;

  duration: number;

  cleanUpTime?: number;

  effectiveScheduleAt: Date;
}
