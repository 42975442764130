export class Profile {
  id: string;

  firstName: string;

  lastName: string;

  middleName?: string;

  gender?: string;

  dateOfBirth?: Date;

  email?: string;

  address1?: string;

  address2?: string;

  city?: string;

  state?: string;

  zipcode?: string;

  country?: string;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;

  fullName: string;
}
