import { Profile } from './profile';
import { TherapistOrder } from './therapist_order';
import { TherapistSchedule } from './therapist_schedule';
import { Role } from './role';
import { OrderLines } from './order_lines';
import { Appointment } from './appointment';
import { EmployeeSchedule } from './employee_schedule';

export class EmployeeRelations {
  Profile: Profile;

  TherapistOrders: TherapistOrder[];

  TherapistSchedules: TherapistSchedule[];

  Role?: Role;

  OrderLines: OrderLines[];

  Appointments: Appointment[];

  EmployeeSchedules: EmployeeSchedule[];
}
