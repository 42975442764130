export class AvailableServiceDateslots {
  calendarId: string;

  date: Date;

  productId: string;

  businessStart?: Date;

  businessEnd?: Date;

  description?: string;
}
