export class Identity {
  id: string;

  profileId: string;

  username?: string;

  hash?: string;

  tag?: string;

  status: number;

  verified: boolean;

  lastLoggedAt?: Date;

  failedAttempts?: number;

  lastFailedAttemptAt?: Date;

  tokenIssuedAt?: Date;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
