type Flattened = {
  id: string;
  parentId: string;
};
type HasChildren = {
  children: (TreeNode & Record<string, any>)[];
};

type TreeNode = Flattened & HasChildren;

// type TreeNode = Flattened & {
//   children?: (TreeNode & Record<string, any>)[];
// };

declare global {
  interface Array<T> {
    unflatten(parentId?: string): (TreeNode & Record<string, any>)[];
    removeChildrenIfEmpty<T>(): (T & HasChildren)[];
    groupBy<K extends keyof T>(k: K): Array<{ key: T[K]; items: T[] }>;
  }
}

if (!Array.prototype.groupBy) {
  Array.prototype.groupBy = function <T, K extends keyof T>(k: K) {
    const groups: { [key: string]: T[] } = {};
    this.forEach((item: T) => {
      const key = item[k];
      const groupKey = typeof key === 'object' ? JSON.stringify(key) : String(key);
      if (!groups[groupKey]) {
        groups[groupKey] = [];
      }
      groups[groupKey].push(item);
    });

    return Object.entries(groups).map(([key, items]) => ({ key: JSON.parse(key), items }));
  };
}

if (!Array.prototype.unflatten) {
  Array.prototype.unflatten = function (parentId?: string): (TreeNode & Record<string, any>)[] {
    return this?.filter((el: { parentId: string }) => el.parentId == parentId).map((item: { id: string }) => ({ id: item.id, ...item, children: this.unflatten(item.id) }));
  };
}

if (!Array.prototype.removeChildrenIfEmpty) {
  Array.prototype.removeChildrenIfEmpty = function <T>(): (T & HasChildren)[] {
    return this.filter((item: HasChildren) => {
      if (item.children && item.children.length === 0) return false;

      item.children = item.children?.removeChildrenIfEmpty();
      return true;
    });
  };
}

export {};
