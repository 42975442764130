export class Category {
  id: string;

  name: string;

  type?: number;

  description?: string;

  parentId?: string;

  meta?: object;

  status: number;

  tags?: string;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
