export class Role {
  id: string;

  parentId?: string;

  departmentId?: string;

  type: number;

  sequence: number;

  name: string;

  tags?: string;

  description?: string;

  status: number;

  meta?: object;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
