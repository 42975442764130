export class History {
  id: string;

  relationId: string;

  level: number;

  message: string;

  meta?: object;

  createdBy: string;

  createdAt: Date;
}
