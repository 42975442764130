export class Note {
  id: string;

  relationId?: string;

  message: string;

  level: number;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy?: string;

  updatedAt?: Date;
}
