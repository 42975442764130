export class Calendar {
  id: string;

  date: Date;

  day: number;

  daySuffix: string;

  weekday: number;

  weekDayName: string;

  isWeekend: boolean;

  isHoliday: boolean;

  isBankHoliday: boolean;

  holidayText?: string;

  dayOfYear: number;

  weekOfMonth: number;

  weekOfYear: number;

  month: number;

  monthName: string;

  quarter: number;

  quarterName: string;

  year: number;

  monthYear: string;

  firstDayOfMonth: Date;

  lastDayOfMonth: Date;

  firstDayOfQuarter: Date;

  lastDayOfQuarter: Date;

  firstDayOfYear: Date;

  lastDayOfYear: Date;
}
