export class TherapistSchedule {
  id: string;

  employeeId: string;

  calendarId: string;

  workStart?: Date;

  workEnd?: Date;

  lunchStart?: Date;

  lunchEnd?: Date;

  description?: string;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
