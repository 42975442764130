import { Station } from './station';
import { Role } from './role';
import { Product } from './product';

export class ProductsByServiceGroupRelations {
  Station: Station;

  Role: Role;

  Product: Product;
}
