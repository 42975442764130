import dayjs, { OpUnitType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { tz } from './date.extensions';

dayjs.extend(utc);
dayjs.extend(timezone);

declare global {
  interface String {
    toProperCase1(): string;
    bool(): boolean;
    isJson(): boolean;
    isIsoDate(): boolean;
    toDate(): Date;
    /** process.env.timezone으로 변환한다. */
    toDateFromLocal(): Date; //! Redis.CacheManager에서 Deserialize하는 경우 class-transformer에 적용받지 않아 Date Type은 여전히 String이므로 Date.prototype.toDateFromLocal(), String.prototype.toDateFromLocal()와 반드시 쌍을 이루어 만들어져야 함.

    /** 처음의 값으로 설정한다. 기본값: 'D': 00:00:00 today  */
    toDateStartOf(unit?: OpUnitType, fromLocal?: boolean): Date;

    /** 마지막의 값으로 설정한다. 기본값: 'D': 00:00:00 today  */
    toDateEndOf(unit?: OpUnitType, fromLocal?: boolean): Date;
    toDayJs(withTimezone?: boolean): dayjs.Dayjs;
    toLocalDayJs(): dayjs.Dayjs;

    toJson(NVP: string);

    /* TAX */
    fromTaxRate(): number;

    /**
     * Return plain integer from rate rate.
     *
     * @example
     * fromTaxRateToInteger(9.99) => 9990
     */
    fromTaxRateToInteger(): number;
    /**
     * Return plain integer from price.
     *
     * @example
     * fromTaxPriceToInteger(9.99) => 999
     */
    fromPriceToInteger(): number;
    isWithinIntervalByUtcString(startUtc: string, endUtc: string, excludeEndUtc?: boolean): boolean;
  }
}

String.prototype.toProperCase1 = function (): string {
  return this.toLowerCase().replace(/\b\w/g, (c: string) => c.toUpperCase());
};

String.prototype.bool = function (): boolean {
  return this.toLowerCase() == 'true';
};

String.prototype.isJson = function (): boolean {
  return /^[\],:{}\s]*$/.test(
    this.replace(/\\["\\\/bfnrtu]/g, '@')
      .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
      .replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
  );
};

String.prototype.isIsoDate = function (): boolean {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(this)) return false;
  const d = new Date(this);
  return d instanceof Date && d.toISOString() === this;
};

if (!String.prototype.toDate) {
  String.prototype.toDate = function (): Date {
    return dayjs(this).toDate();
  };
}

if (!String.prototype.toDateFromLocal) {
  String.prototype.toDateFromLocal = function (): Date {
    return dayjs.tz(this).toDate();
  };
}

if (!String.prototype.toDateStartOf) {
  String.prototype.toDateStartOf = function (unit: OpUnitType = 'D', fromLocal = true): Date {
    return (fromLocal ? dayjs(this).tz(tz, true) : dayjs(this)).startOf(unit).toDate();
  };
}

if (!String.prototype.toDateEndOf) {
  String.prototype.toDateEndOf = function (unit: OpUnitType = 'D', fromLocal = true): Date {
    return (fromLocal ? dayjs(this).tz(tz, true) : dayjs(this)).endOf(unit).toDate();
  };
}

if (!String.prototype.toDayJs) {
  String.prototype.toDayJs = function (withTimezone = true): dayjs.Dayjs {
    return withTimezone ? dayjs.tz(this) : dayjs(this);
  };
}

if (!String.prototype.toLocalDayJs) {
  String.prototype.toLocalDayJs = function (): dayjs.Dayjs {
    return dayjs(this).tz(tz);
  };
}

if (!String.prototype.toJson) {
  String.prototype.toJson = function (NVP: string) {
    const res = {};
    const pairs = [];
    const ignore = {};
    let search;
    let regexSearchIndex = 0;
    while ((search = NVP.slice(regexSearchIndex).search(/\[[0-9]+\]=/)) !== -1) {
      const val = parseInt(NVP.substring(search + regexSearchIndex + 1, NVP.indexOf(']', search + regexSearchIndex)));
      ignore[search + regexSearchIndex] = val;
      NVP = NVP.slice(0, regexSearchIndex) + NVP.slice(regexSearchIndex).replace(/\[[0-9]+\]=/, '='); // Remove first remaining (current) instance from data.
      regexSearchIndex += search + val;
    }
    let lastPair = -1;
    for (let i = 0; i < NVP.length; i++) {
      if (ignore[i]) {
        i += ignore[i];
        continue;
      }
      if (NVP.charAt(i) === '&') {
        pairs.push(NVP.substring(lastPair + 1, i));
        lastPair = i;
      }
    }
    pairs.push(NVP.substring(lastPair + 1)); // Add in last pair.
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split(/=(.+)/);
      res[pair[0]] = pair[1];
    }
    return res;
  };
}

/* TAX */
if (!String.prototype.fromTaxRate) {
  String.prototype.fromTaxRate = function (): number {
    return 0;
  };
}

if (!String.prototype.fromTaxRateToInteger) {
  String.prototype.fromTaxRateToInteger = function (): number {
    return +(+this * 1000).toPrecision(5);
  };
}

if (!String.prototype.fromPriceToInteger) {
  String.prototype.fromPriceToInteger = function (): number {
    return +(parseFloat(this) * 100).toPrecision(this.length);
    // return +(parseFloat(this)) * 100;
  };
}

if (!String.prototype.isWithinIntervalByUtcString) {
  String.prototype.isWithinIntervalByUtcString = function (startUtc: string, endUtc: string, excludeEndUtc: boolean = false): boolean {
    try {
      if (!this || !startUtc || !endUtc) return false;
      const start = dayjs(startUtc).tz(tz).toDate();
      let end = dayjs(endUtc).tz(tz).toDate();
      if (excludeEndUtc) end = end.add(-1, 'millisecond');
      if (!end.isAfter(start)) return false;
      return dayjs(this).tz(tz).isBetween(start, end, 'day', '[]');
    } catch (e) {
      console.error(e);
      return false;
    }
  };
}

export {};
