export class RoomByProductService {
  id: string;

  name: string;

  capacity: number;

  priority: number;

  productId: string;
}
