import { Calendar } from './calendar';
import { Employee } from './employee';
import { Customer } from './customer';
import { Room } from './room';
import { TherapistSchedule } from './therapist_schedule';
import { OrderLines } from './order_lines';
import { Note } from './note';
import { RoomByProductService } from './room_by_product_service';

export class AppointmentRelations {
  Calendar: Calendar;

  Employee?: Employee;

  Customer?: Customer;

  Room?: Room;

  TherapistSchedule?: TherapistSchedule;

  OrderLines: OrderLines[];

  Notes: Note[];

  ParentNotes: Note[];

  RoomByProductService?: RoomByProductService;
}
