export class Order {
  id: string;

  channelId?: string;

  orderNumber: string;

  status: number;

  deviceId: string;

  cashierId: string;

  cashierName: string;

  customerId?: string;

  receiptEmail?: string;

  amount: number;

  tax1Amount: number;

  tax2Amount: number;

  extraChargeAmount: number;

  tip1Amount: number;

  tip2Amount: number;

  discount1Amount: number;

  discount2Amount: number;

  discount3Amount: number;

  shippingAmount: number;

  totalAmount: number;

  paidAmount: number;

  refundAmount: number;

  refundShippingAmount: number;

  completedAt?: Date;

  refundedAt?: Date;

  description?: string;

  ipAddress?: string;

  meta?: object;

  orderId?: string;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;

  updatedByName: string;

  lineCount?: number;

  lockerNumbers?: string;

  tenderCount?: number;

  tenders?: string;
}
