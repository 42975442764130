export * from './core';
export * from './duplex';
import { Operation } from './core';

export type OperationGroup = (Operation[] & { index: number; path: string })[];
export const createOperationByIndex = (operations: Operation[], op?: 'add' | 'remove' | 'replace' | 'move' | 'copy' | 'test' | '_get'): OperationGroup => {
  //* 여러개의 index가 있는 경우 정상 동작하지 않음. 현재 첫 번째 index만 처리함.
  const operationGroup: OperationGroup = [];

  for (const patch of operations.filter(el => !op || el.op == op)) {
    const paths = patch.path.split('/').filter(Boolean);
    let currentPath = [];
    for (const path of paths) {
      currentPath.push(path);
      const index = parseInt(path);
      if (!isNaN(index)) {
        if (!operationGroup[path]) operationGroup[path] = [];
        operationGroup[path].path = `/${currentPath.slice(0, -1).join('/')}`;
        operationGroup[path].index = index;
        operationGroup[path].push({
          ...patch,
          path: patch.path.replace(`/${currentPath.join('/')}`, ''),
        });
        break;
      }
    }
  }

  return operationGroup.filter(el => el && el.every(e => e.path));
};
