import { DecimalType, DayNameType } from '@edge/type';
import { format, parse, set, isWithinInterval } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import '@edge/common';

const timeZone = 'America/New_York';

export const getDiscountPrice = (originalPrice: number, eventParams: { rateType: DecimalType; rate: number }) => {
	switch (eventParams.rateType) {
	  case DecimalType.Fixed:
		return originalPrice - eventParams.rate;
	  case DecimalType.Percent:
		return originalPrice - originalPrice * (eventParams.rate / 100000);
	  default:
		return originalPrice;
	}
};

export const isWithinCampaignDate = (utcString:string, campaignDayStartDateString:string, campaignDayEndDateString:string) => utcString?.isWithinIntervalByUtcString(campaignDayStartDateString, campaignDayEndDateString);

export const isCampaignDayType = (dayOfWeek: DayNameType, campaignDayTypes: DayNameType[]) => campaignDayTypes?.includes(dayOfWeek);
export const isCampaignTimeslot = (utcString:string, campaignDayStartTimeString:string, campaignDayEndTimeString:string) => isTimeWithinInterval(utcString, campaignDayStartTimeString, campaignDayEndTimeString);


export function isTimeWithinInterval(time: string, startTime: string, endTime: string): boolean {
	if(!time || !startTime || !endTime) return false;
    const zonedTimeByTimeslotTime = utcToZonedTime(time, timeZone);
    const currentTime = format(zonedTimeByTimeslotTime, 'HH:mm');
  
    const parseTime = (timeStr: string) => parse(timeStr, 'HH:mm', zonedTimeByTimeslotTime);
    const setDayToSameDay = (time: Date, timeslotDate: Date) => set(time, { year: timeslotDate.getFullYear(), month: timeslotDate.getMonth(), date: timeslotDate.getDate() });
  
    const parsedCurrentTime = parseTime(currentTime);
    const parsedStartTime = parseTime(startTime);
    const parsedEndTime = parseTime(endTime);
  
    const normalizedCurrent = setDayToSameDay(parsedCurrentTime, zonedTimeByTimeslotTime);
    const normalizedStart = setDayToSameDay(parsedStartTime, zonedTimeByTimeslotTime);
    const normalizedEnd = setDayToSameDay(parsedEndTime, zonedTimeByTimeslotTime);
  
    return isWithinInterval(normalizedCurrent, {
      start: normalizedStart,
      end: normalizedEnd,
    });
  }