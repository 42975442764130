export class ProductServiceMap {
  id: string;

  productId: string;

  type: number;

  relationId: string;

  priority?: number;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
