export class Room {
  id: string;

  name: string;

  groupName?: string;

  description?: string;

  capacity: number;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
