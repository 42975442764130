export class ProductOnline {
  id: string;

  productId: string;

  slug: string;

  name: string;

  nameHtml: string;

  summaryHtml?: string;

  textHtml: string;

  headerHtml?: string;

  extraTextHtml?: string;

  imageUrl?: string;

  price: number;

  meta?: object;

  publishedAt: Date;

  visibility: number;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
