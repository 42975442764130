export class ProductsByServiceGroup {
  stationId: string;

  roleId: string;

  serviceGroup: string;

  productId: string;

  productName: string;

  productServiceType: number;

  productServiceDuration: number;

  productServiceCleanUpTime: number;

  price: number;

  productMeta?: object;

  productOnlineMeta?: object;

  parentProductId?: string;

  addOnServiceId?: string;
}
