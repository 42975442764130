import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = [
  "getItems",
  "getServices",
  "getProductServiceTypes",
  "getItem",
  "updateItem",
  "deleteItem",
  "createItem",
  "updateProductRooms",
  "updateProductEmployees",
  "getRetailItems",
  "getRetailItem",
  "updateRetailItem",
  "createRetailItem",
  "syncViaNz",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getItems: build.query<GetItemsApiResponse, GetItemsApiArg>({
        query: (queryArg) => ({
          url: `/product/items`,
          params: {
            categoryId: queryArg.categoryId,
            keyword: queryArg.keyword,
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            sortDirection: queryArg.sortDirection,
            sortBy: queryArg.sortBy,
            status: queryArg.status,
          },
        }),
        providesTags: ["getItems"],
      }),
      getServices: build.query<GetServicesApiResponse, GetServicesApiArg>({
        query: () => ({ url: `/product/services` }),
        providesTags: ["getServices"],
      }),
      getProductServiceTypes: build.query<
        GetProductServiceTypesApiResponse,
        GetProductServiceTypesApiArg
      >({
        query: () => ({ url: `/product/item/serviceTypes` }),
        providesTags: ["getProductServiceTypes"],
      }),
      getItem: build.query<GetItemApiResponse, GetItemApiArg>({
        query: (queryArg) => ({ url: `/product/item/${queryArg.id}` }),
        providesTags: ["getItem"],
      }),
      updateItem: build.mutation<UpdateItemApiResponse, UpdateItemApiArg>({
        query: (queryArg) => ({
          url: `/product/item/${queryArg.id}`,
          method: "PATCH",
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ["updateItem"],
      }),
      deleteItem: build.mutation<DeleteItemApiResponse, DeleteItemApiArg>({
        query: (queryArg) => ({
          url: `/product/item/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["deleteItem"],
      }),
      createItem: build.mutation<CreateItemApiResponse, CreateItemApiArg>({
        query: (queryArg) => ({
          url: `/product/item`,
          method: "POST",
          data: queryArg.createProductRequest,
        }),
        invalidatesTags: ["createItem"],
      }),
      updateProductRooms: build.mutation<
        UpdateProductRoomsApiResponse,
        UpdateProductRoomsApiArg
      >({
        query: (queryArg) => ({
          url: `/product/item/rooms/${queryArg.id}`,
          method: "PUT",
          data: queryArg.productRoomPutRequest,
        }),
        invalidatesTags: ["updateProductRooms"],
      }),
      updateProductEmployees: build.mutation<
        UpdateProductEmployeesApiResponse,
        UpdateProductEmployeesApiArg
      >({
        query: (queryArg) => ({
          url: `/product/item/employees/${queryArg.id}`,
          method: "PUT",
          data: queryArg.productTherapistPutRequest,
        }),
        invalidatesTags: ["updateProductEmployees"],
      }),
      getRetailItems: build.query<
        GetRetailItemsApiResponse,
        GetRetailItemsApiArg
      >({
        query: (queryArg) => ({
          url: `/product/retailItems`,
          params: {
            categoryId: queryArg.categoryId,
            keyword: queryArg.keyword,
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            sortDirection: queryArg.sortDirection,
            sortBy: queryArg.sortBy,
            status: queryArg.status,
          },
        }),
        providesTags: ["getRetailItems"],
      }),
      getRetailItem: build.query<GetRetailItemApiResponse, GetRetailItemApiArg>(
        {
          query: (queryArg) => ({ url: `/product/retailItem/${queryArg.id}` }),
          providesTags: ["getRetailItem"],
        },
      ),
      updateRetailItem: build.mutation<
        UpdateRetailItemApiResponse,
        UpdateRetailItemApiArg
      >({
        query: (queryArg) => ({
          url: `/product/retailItem/${queryArg.id}`,
          method: "PATCH",
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ["updateRetailItem"],
      }),
      createRetailItem: build.mutation<
        CreateRetailItemApiResponse,
        CreateRetailItemApiArg
      >({
        query: (queryArg) => ({
          url: `/product/retailItem`,
          method: "POST",
          data: queryArg.createRetailProductRequest,
        }),
        invalidatesTags: ["createRetailItem"],
      }),
      syncViaNz: build.mutation<SyncViaNzApiResponse, SyncViaNzApiArg>({
        query: () => ({ url: `/product/syncViaNZ`, method: "POST" }),
        invalidatesTags: ["syncViaNz"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeProductApi };
export type GetItemsApiResponse = /** status 200  */ ProductsResponse;
export type GetItemsApiArg = {
  /** Category Id */
  categoryId?: string;
  /** Product Name */
  keyword?: string;
  page?: number;
  pageSize?: number;
  /** asc or desc */
  sortDirection?: string;
  sortBy?: string;
  status?: number;
};
export type GetServicesApiResponse = /** status 200  */ ServicesResponse;
export type GetServicesApiArg = void;
export type GetProductServiceTypesApiResponse =
  /** status 200  */ ProductServiceTypeResponse;
export type GetProductServiceTypesApiArg = void;
export type GetItemApiResponse = /** status 200  */ ProductResponse;
export type GetItemApiArg = {
  /** categoryId */
  id: string;
};
export type UpdateItemApiResponse = /** status 200  */ ProductResponse;
export type UpdateItemApiArg = {
  id: string;
  patchRequest: PatchRequest;
};
export type DeleteItemApiResponse = /** status 200  */ VoidResponse;
export type DeleteItemApiArg = {
  id: string;
};
export type CreateItemApiResponse = /** status 200  */ ProductResponse;
export type CreateItemApiArg = {
  createProductRequest: CreateProductRequest;
};
export type UpdateProductRoomsApiResponse =
  /** status 200  */ UpdateProductRoomsResponse;
export type UpdateProductRoomsApiArg = {
  id: string;
  productRoomPutRequest: ProductRoomPutRequest;
};
export type UpdateProductEmployeesApiResponse =
  /** status 200  */ UpdateProductTherapistsResponse;
export type UpdateProductEmployeesApiArg = {
  id: string;
  productTherapistPutRequest: ProductTherapistPutRequest;
};
export type GetRetailItemsApiResponse = /** status 200  */ ProductsResponse;
export type GetRetailItemsApiArg = {
  /** Category Id */
  categoryId?: string;
  /** Product Name */
  keyword?: string;
  page?: number;
  pageSize?: number;
  /** asc or desc */
  sortDirection?: string;
  sortBy?: string;
  status?: number;
};
export type GetRetailItemApiResponse =
  /** status 200  */ SingleRetailProductResponse;
export type GetRetailItemApiArg = {
  /** categoryId */
  id: string;
};
export type UpdateRetailItemApiResponse =
  /** status 200  */ SingleRetailProductResponse;
export type UpdateRetailItemApiArg = {
  id: string;
  patchRequest: PatchRequest;
};
export type CreateRetailItemApiResponse =
  /** status 200  */ SingleRetailProductResponse;
export type CreateRetailItemApiArg = {
  createRetailProductRequest: CreateRetailProductRequest;
};
export type SyncViaNzApiResponse = unknown;
export type SyncViaNzApiArg = void;
export type ProductMeta = {
  templateName?: string;
  expiration?: number;
  followListingPrice?: boolean;
  onlinePriceMethod?: number;
};
export type CategoryName = {
  name: string;
};
export type ProductServiceDto = {
  id: string;
  serviceType: number;
  sequence?: number;
  capacity: number;
  duration: number;
  cleanUpTime?: number;
  effectiveScheduleAt: string;
};
export type TaxDto = {
  id: string;
  name: string;
  taxCode: string;
  description?: string;
  rate: number;
  type?: number;
  status: number;
};
export type ProductDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  categoryId?: string;
  name: string;
  description?: string;
  gtin?: string;
  brand?: string;
  mpn?: string;
  price: number;
  taxId1?: string;
  taxId2?: string;
  taxId3?: string;
  tipId?: string;
  isCommissionOnRetailSales: boolean;
  isTaxDefault: boolean;
  isCommissionOnRetailSalesDefault: boolean;
  isPrepaidOnly?: boolean;
  tags?: string;
  meta?: ProductMeta;
  status: number;
  Department?: string;
  Category?: CategoryName;
  ProductService?: ProductServiceDto;
  Taxes?: TaxDto[];
};
export type ProductsResponse = {
  code?: number;
  messages?: string[];
  result?: ProductDto[];
  pageSize?: number;
  totalRecords?: number;
  totalPages?: number;
};
export type ServiceDto = {
  id: string;
  duration: number;
  type: 1 | 2 | 4 | 8 | 16;
  name: string;
  children?: ServiceDto[];
};
export type ServicesResponse = {
  code?: number;
  messages?: string[];
  result?: ServiceDto[];
};
export type ProductServiceTypesDto = {
  serviceType: number;
  serviceName?: string;
};
export type ProductServiceTypeResponse = {
  code?: number;
  messages?: string[];
  result?: ProductServiceTypesDto[];
};
export type ProductCategory = {
  name: string;
  path: string;
  level: number;
};
export type SingleProductServiceDto = {
  id: string;
  serviceType: number;
  capacity: number;
  duration: number;
  cleanUpTime?: number;
  effectiveScheduleAt: string;
};
export type SingleProductRoomDto = {
  id: string;
  name: string;
  description?: string;
  capacity: number;
  status: number;
  priority?: number;
};
export type RoleDepartmentDto = {
  id?: string;
  name?: string;
};
export type TherapistRoleDto = {
  name: string;
  Department: RoleDepartmentDto;
};
export type SingleProductTherapistDto = {
  id?: string;
  fullName?: string;
  gender?: string;
  Role?: TherapistRoleDto;
};
export type Visibility = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
export type ProductOnlineVisibility = {
  id: string;
  visibility: Visibility;
};
export type SingleProductDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  categoryId?: string;
  name: string;
  description?: string;
  gtin?: string;
  brand?: string;
  mpn?: string;
  price: number;
  taxId1?: string;
  taxId2?: string;
  taxId3?: string;
  tipId?: string;
  isCommissionOnRetailSales: boolean;
  isTaxDefault: boolean;
  isCommissionOnRetailSalesDefault: boolean;
  isPrepaidOnly?: boolean;
  tags?: string;
  meta?: ProductMeta;
  status: number;
  Category?: ProductCategory;
  Taxes?: TaxDto[];
  afterTaxPrice?: number;
  ProductService?: SingleProductServiceDto;
  Rooms?: SingleProductRoomDto[];
  Therapists?: SingleProductTherapistDto[];
  ProductOnline?: ProductOnlineVisibility;
};
export type ProductResponse = {
  code?: number;
  messages?: string[];
  result?: SingleProductDto;
};
export type PatchOperation =
  | "add"
  | "replace"
  | "test"
  | "remove"
  | "move"
  | "copy";
export type Patch = {
  op: PatchOperation;
  /** Path */
  path: string;
  /** Value */
  value?: object;
  /** From */
  from?: string;
};
export type PatchRequest = {
  jsonPatch: Patch[];
};
export type VoidResponse = {
  code?: number;
  messages?: string[];
};
export type ProductRoomRequest = {
  id: string;
  priority: number;
};
export type CreateProductRequest = {
  categoryId?: string;
  name: string;
  description?: string;
  price: number;
  isTaxDefault: boolean;
  isPrepaidOnly?: boolean;
  tags?: string;
  /** ["TX123456789","TX123456789"] */
  taxIds?: string[];
  /** Service:1, Couples Service:2, VSB:3, Halo:4 */
  serviceType: number;
  capacity: number;
  duration: number;
  cleanUpTime: number;
  therapistIds: string[];
  room: ProductRoomRequest[];
  visibility: Visibility;
  effectiveScheduleAt: string;
};
export type UpdateProductRoomsResponse = {
  code?: number;
  messages?: string[];
  result?: SingleProductRoomDto[];
};
export type ProductRoomPutRequest = {
  ProductRoomPatchRequest: ProductRoomRequest[];
};
export type UpdateProductTherapistsResponse = {
  code?: number;
  messages?: string[];
  result?: SingleProductTherapistDto[];
};
export type ProductTherapistPutRequest = {
  /** ["EP123456789012","EP123456789012"] */
  employeeIds: string[];
};
export type SingleRetailProductDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  categoryId?: string;
  name: string;
  description?: string;
  gtin?: string;
  brand?: string;
  mpn?: string;
  price: number;
  taxId1?: string;
  taxId2?: string;
  taxId3?: string;
  tipId?: string;
  isCommissionOnRetailSales: boolean;
  isTaxDefault: boolean;
  isCommissionOnRetailSalesDefault: boolean;
  isPrepaidOnly?: boolean;
  tags?: string;
  meta?: ProductMeta;
  status: number;
  Category?: ProductCategory;
  Taxes?: TaxDto[];
  afterTaxPrice?: number;
};
export type SingleRetailProductResponse = {
  code?: number;
  messages?: string[];
  result?: SingleRetailProductDto;
};
export type CreateRetailProductRequest = {
  categoryId?: string;
  name: string;
  description?: string;
  gtin?: string;
  mpn?: string;
  price: number;
  isCommissionOnRetailSales: boolean;
  isTaxDefault: boolean;
  isCommissionOnRetailSalesDefault: boolean;
  isPrepaidOnly?: boolean;
  tags?: string;
  taxIds?: string[];
};
export const {
  useGetItemsQuery,
  useGetServicesQuery,
  useGetProductServiceTypesQuery,
  useGetItemQuery,
  useUpdateItemMutation,
  useDeleteItemMutation,
  useCreateItemMutation,
  useUpdateProductRoomsMutation,
  useUpdateProductEmployeesMutation,
  useGetRetailItemsQuery,
  useGetRetailItemQuery,
  useUpdateRetailItemMutation,
  useCreateRetailItemMutation,
  useSyncViaNzMutation,
} = injectedRtkApi;
