import { Calendar } from './calendar';
import { Employee } from './employee';
import { TherapistOrder } from './therapist_order';
import { Appointment } from './appointment';

export class TherapistScheduleRelations {
  Calendar: Calendar;

  Employee: Employee;

  TherapistOrder?: TherapistOrder;

  Appointments: Appointment[];
}
