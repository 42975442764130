import { ApiKey } from './api_key';
import { ExternalIdentity } from './external_identity';
import { Profile } from './profile';

export class IdentityRelations {
  ApiKey?: ApiKey;

  ExternalIdentity?: ExternalIdentity;

  Profile: Profile;
}
