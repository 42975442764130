import { Appointment } from './appointment';
import { Profile } from './profile';

export class NoteRelations {
  Appointment?: Appointment;

  Profile: Profile;

  ParentAppointment?: Appointment;
}
