export class Channel {
  id: string;

  profileId: string;

  customerNumber?: string;

  orderNumber: string;

  paymentNumber?: string;

  requestMessage?: string;

  legacyNumber?: string;

  legacyMessage?: string;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
