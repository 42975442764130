import { Reservation } from './reservation';
import { Dateslot } from './dateslot';
import { ProductPriceByTimeslot } from './product_price_by_timeslot';

export class TimeslotRelations {
  Reservations: Reservation[];

  Dateslot: Dateslot;

  ProductPriceByTimeslots: ProductPriceByTimeslot[];
}
