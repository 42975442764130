import { ProductPriceByTimeslot } from './product_price_by_timeslot';
import { Timeslot } from './timeslot';
import { Order } from './order';
import { Ticket } from './ticket';

export class ReservationRelations {
  ProductPriceByTimeslot: ProductPriceByTimeslot;

  Timeslot: Timeslot;

  Order?: Order;

  Tickets: Ticket[];
}
