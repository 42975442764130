export class AvailableTimeslot {
  date: Date;

  datetime: Date;

  productId?: string;

  productName?: string;

  price: number;

  dateslotId?: string;

  timeslotId?: string;

  timeslotStatus?: number;

  capacity: number;

  reserved: number;

  available: number;
}
