import { OrderLines } from './order_lines';
import { PaymentLines } from './payment_lines';
import { Channel } from './channel';
import { Customer } from './customer';
import { Reservation } from './reservation';

export class OrderRelations {
  OrderLines: OrderLines[];

  PaymentLines: PaymentLines[];

  Channel?: Channel;

  Customer?: Customer;

  Reservation?: Reservation;
}
