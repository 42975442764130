export class Cart {
  id: string;
  items: CartItem[];
}
export class CartItem {
  id: string;
  productId?: string;
  orderId?: string;
  orderNumber?: string;
  quantity?: number;
  meta?: any;
}
