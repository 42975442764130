import { Dateslot } from './dateslot';
import { TherapistOrder } from './therapist_order';
import { TherapistSchedule } from './therapist_schedule';
import { BusinessSchedule } from './business_schedule';
import { Appointment } from './appointment';

export class CalendarRelations {
  Dateslot?: Dateslot;

  TherapistOrders: TherapistOrder[];

  TherapistSchedules: TherapistSchedule[];

  BusinessSchedules: BusinessSchedule[];

  Appointments: Appointment[];
}
