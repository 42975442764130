export class StationMap {
  id: string;

  type: number;

  stationId: string;

  relationId: string;

  sequence: number;

  status: number;

  name: string;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
