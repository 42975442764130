export class OrderShipping {
  id: string;

  type: number;

  addressId?: string;

  trackingNumber?: string;

  shippingDate?: Date;

  senderName?: string;

  senderEmail?: string;

  recipientName?: string;

  recipientEmail?: string;

  pickupFullName?: string;

  pickupDateTime?: Date;

  pickupPhone?: string;

  isPickupAgreeTerms?: boolean;

  personalMessage?: string;

  requestMessage?: string;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
