import { Profile } from './profile';
import { CustomerAddresses } from './customer_addresses';
import { Order } from './order';
import { Appointment } from './appointment';

export class CustomerRelations {
  Profile: Profile;

  CustomerAddresses: CustomerAddresses[];

  Orders: Order[];

  Appointments: Appointment[];
}
