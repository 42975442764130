export class Station {
  id: string;

  locationId: string;

  name: string;

  sequence: number;

  tags?: string;

  meta?: object;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
