export class EmployeeSchedule {
  employeeId: string;

  dayNameType: number;

  workStart?: number;

  workEnd?: number;

  lunchStart?: number;

  lunchEnd?: number;
}
