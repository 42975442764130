import { Reservation } from './reservation';
import { Product } from './product';
import { Timeslot } from './timeslot';

export class ProductPriceByTimeslotRelations {
  Reservation: Reservation[];

  Product: Product;

  Timeslot: Timeslot;
}
