export class Tax {
  id: string;

  name: string;

  taxCode: string;

  description?: string;

  type?: number;

  rate: number;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
