export class GrantedPermission {
  id: string;

  roleId: string;

  permissionId: string;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
