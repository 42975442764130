export class Appointment {
  id: string;

  sequence: number;

  stationId: string;

  calendarId: string;

  serviceId?: string;

  addOnServiceId?: string;

  parentServiceId?: string;

  customerId?: string;

  contact1Id?: string;

  contact2Id?: string;

  therapistId?: string;

  roomId?: string;

  preferenceGender: number;

  preferenceTherapistId?: string;

  appointmentType: number;

  serviceStartAt: Date;

  serviceEndAt: Date;

  cleaningStartAt?: Date;

  cleaningEndAt?: Date;

  serviceCheckInAt?: Date;

  serviceCheckOutAt?: Date;

  buildingCheckInAt?: Date;

  buildingCheckOutAt?: Date;

  confirmedByUserAt?: Date;

  confirmedByGuestAt?: Date;

  cancelledAt?: Date;

  balancePaidOffAt?: Date;

  groupKey?: string;

  linkKey?: string;

  refKey?: string;

  status: number;

  description?: string;

  appointmentId?: string;

  refs?: string;

  meta?: object;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;

  serviceName?: string;

  parentServiceName?: string;

  customerName?: string;

  therapistName?: string;

  therapistGender?: string;

  roomName?: string;

  contact1Name?: string;

  contact1Phone?: string;

  contact1Email?: string;

  contact2Name?: string;

  contact2Phone?: string;

  contact2Email?: string;
}
