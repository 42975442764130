import { Product } from './product';
import { ProductOnlineMap } from './product_online_map';
import { OnlineTags } from './online_tags';

export class ProductOnlineRelations {
  Product: Product;

  ProductOnlineMaps: ProductOnlineMap[];

  OnlineTags: OnlineTags[];

  RelatedProducts: ProductOnlineMap[];
}
