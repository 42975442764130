export type TPromotionConfig = {
  startDate: string; // start date UTC for dateslot e.g. "2024-12-05T05:00:00Z"
  endDate: string; // end date UTC for dateslot e.g. "2024-12-19T04:59:59Z"
  promotionDescription?: string; // description
  promotionProductIds?: string[]; // promotion product ids if there is no value then all service of serviceGroup.
  startTime: string; // start time(included) for timeslot e.g. "10:00"
  endTime: string; // end time(included) for timeslot e.g. "18:00"
  promotionTimeslotDescription?: string; // timeslot description
  promotionTimeslotDiscountPercentage?: number; // early bird timeslot discount

  //* calendarId or dayType can both be used to filter dates but recommend using only one of them.
  dayTypes?: number[]; // day types (0-6) e.g. "dayTypes":[0, 1, 2, 3, 4, 5, 6]
  calendarIds?: string[]; // calendar ids e.g. "calendarIds": ["DAYCAL20241205","DAYCAL20241212","DAYCAL20241217","DAYCAL20241218","DAYCAL20241219"]
};
export type SpaPromotion = { SpaPromotion: { EspaPromotion: TPromotionConfig; KbsPromotion: TPromotionConfig; AcuPromotion: TPromotionConfig } } | null;

export enum PromotionType {
  Espa = 'Espa',
  Kbs = 'Kbs',
  Acu = 'Acu',
}
export type PromotionTypeValue = keyof typeof PromotionType;
export const PROMOTION_TYPES = Object.values(PromotionType);

// export const getPromotionConfig = (type: PromotionType, promotionConfig: SpaPromotion): TPromotionConfig => {
//   const config = (promotionConfig?.SpaPromotion?.[`${type}Promotion`] ?? {}) as TPromotionConfig;
//   return {
//     startDate: config?.startDate ?? null,
//     endDate: config?.endDate ?? null,
//     dayTypes: config?.dayTypes ?? null,
//     calendarIds: config?.calendarIds ?? null,
//     startTime: config?.startTime ?? null,
//     endTime: config?.endTime ?? null,
//     promotionDescription: config?.promotionDescription ?? null,
//     promotionTimeslotDescription: config?.promotionTimeslotDescription ?? null,
//     promotionTimeslotDiscountPercentage: config?.promotionTimeslotDiscountPercentage ?? null,
//   };
// };

// export const getProductIdsByStation = <T extends { name?: string; products?: TProduct[] }, TProduct extends { id?: string }>(productsByServiceGroup: T[], stationName: string, options?: { excludeId?: string }) => {
//   const products = productsByServiceGroup?.filter(el => el.name == stationName)?.flatMap(el => el.products?.map(el => el.id)) ?? [];
//   return options?.excludeId ? products.filter(id => id !== options.excludeId) : products;
// };

// export const getProductIdsByAllStations = <T extends { name?: string; products?: TProduct[] }, TProduct extends { id?: string }>(productsByServiceGroup: T[]): Record<PromotionTypeValue, string[]> => ({
//   [PromotionType.Espa]: getProductIdsByStation(productsByServiceGroup, 'ESPA', { excludeId: 'PD13QSC11Z1OQQ' }),
//   [PromotionType.Kbs]: getProductIdsByStation(productsByServiceGroup, 'KBS'),
//   [PromotionType.Acu]: getProductIdsByStation(productsByServiceGroup, 'Acupressure'),
// });
