import dayjs, { ConfigType, ManipulateType, OpUnitType, QUnitType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import pluralGetSet from 'dayjs/plugin/pluralGetSet';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import toObject from 'dayjs/plugin/toObject';

export const tz = 'America/New_York';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(pluralGetSet);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(toObject);
dayjs.extend(isBetween);
dayjs.tz.setDefault(tz);

declare global {
  interface Date {
    /** process.env.timezone으로 변환한다. */
    toDateFromLocal(): Date; //! Redis.CacheManager에서 Deserialize하는 경우 class-transformer에 적용받지 않아 Date Type은 여전히 String이므로 Date.prototype.toDateFromLocal(), String.prototype.toDateFromLocal()와 반드시 쌍을 이루어 만들어져야 함.

    /** 처음의 값으로 설정한다. 기본값: 'D': 00:00:00 today  */
    startOf(unit?: OpUnitType): Date;

    /** 마지막의 값으로 설정한다. 기본값: 'D': 00:00:00 today  */
    endOf(unit?: OpUnitType): Date;
    validateIfPast(date?: Date): Date;
    validateIfFuture(date?: Date): Date;
    isBefore(date?: ConfigType, unit?: OpUnitType): boolean;
    isAfter(date?: ConfigType, unit?: OpUnitType): boolean;
    add(value: number, unit?: ManipulateType): Date;
    subtract(value: number, unit?: ManipulateType): Date;
    format(format: string): string;

    /** formatDate('MM/DD/YYYY') // => 07/16/2023 */
    formatDate(): string;

    /** formatDate('MM/DD/YY') // => 07/16/20 */
    formatShortDate(): string;

    /** formatDate('ddd, MMM D, YYYY') // => Tue, Feb 14, 2023*/
    formatLongDate(): string;

    /** formatTime('h:mm A') =>	8:02 PM */
    formatTime(): string;

    /** formatTime('LTS') =>	8:02:18 PM */
    formatLongTime(): string;

    /** MM/DD/YYYY h:mm A => 07/6/2023 8:02 PM */
    formatDateTime(): string;

    /** Use a single AM or PM at the end of the range, if both times have the same AM/PM. 8:00–10:30 AM */
    formatBetweenTime(endTime?: Date): string;

    isSameOrAfter(date?: ConfigType, unit?: OpUnitType): boolean;
    isSameOrBefore(date?: ConfigType, unit?: OpUnitType): boolean;
    diff(date?: ConfigType, unit?: QUnitType | OpUnitType): number;

    toDayJs(withTimezone?: boolean): dayjs.Dayjs;
    toLocalDayJs(): dayjs.Dayjs;
  }
}

if (!Date.prototype.toDayJs) {
  Date.prototype.toDayJs = function (withTimezone = true): dayjs.Dayjs {
    return withTimezone ? dayjs.tz(this) : dayjs(this);
  };
}

if (!Date.prototype.toLocalDayJs) {
  Date.prototype.toLocalDayJs = function (): dayjs.Dayjs {
    return dayjs(this).tz(tz);
  };
}

if (!Date.prototype.toDateFromLocal) {
  Date.prototype.toDateFromLocal = function (): Date {
    return new Date(dayjs.tz(this).format('YYYY-MM-DDTHH:mm:ss'));
  };
}

if (!Date.prototype.startOf) {
  Date.prototype.startOf = function (unit: OpUnitType = 'D'): Date {
    return dayjs(this).startOf(unit).toDate();
  };
}

if (!Date.prototype.endOf) {
  Date.prototype.endOf = function (unit: OpUnitType = 'D'): Date {
    return dayjs(this).endOf(unit).toDate();
  };
}

Date.prototype.validateIfPast = function (date: Date = new Date().toDayJs().startOf('D').toDate()): Date {
  // 과거인 경우 당일을 사용.
  return this.isBefore(date, 'D') ? date : this;
};

Date.prototype.validateIfFuture = function (date: Date = new Date().toDayJs().startOf('D').toDate()): Date {
  // 미래인 경우 당일을 사용.
  return this.isBefore(date) ? this : date;
};

Date.prototype.add = function (value: number, unit?: ManipulateType): Date {
  return dayjs(this).add(value, unit).toDate();
};

Date.prototype.subtract = function (value: number, unit?: ManipulateType): Date {
  return dayjs(this).subtract(value, unit).toDate();
};

Date.prototype.format = function (format: string): string {
  return dayjs(this).format(format);
};

Date.prototype.formatDate = function (): string {
  return dayjs(this).format('L');
};

Date.prototype.formatLongDate = function (): string {
  return dayjs(this).format('ddd, MMM D, YYYY');
};

Date.prototype.formatShortDate = function (): string {
  return dayjs(this).format('MM/DD/YY	');
};

Date.prototype.formatTime = function (): string {
  return dayjs(this).format('LT');
};

Date.prototype.formatLongTime = function (): string {
  return dayjs(this).format('LTS');
};

Date.prototype.formatDateTime = function (): string {
  return dayjs(this).format('L LT');
};

Date.prototype.isAfter = function (date: ConfigType = new Date(), unit: OpUnitType = 'millisecond'): boolean {
  return dayjs(this).isAfter(date, unit);
};

Date.prototype.isBefore = function (date: ConfigType = new Date(), unit: OpUnitType = 'milliseconds'): boolean {
  return dayjs(this).isBefore(date, unit);
};

Date.prototype.isSameOrAfter = function (date: ConfigType = new Date(), unit: OpUnitType = 'milliseconds'): boolean {
  return dayjs(this).isSameOrAfter(date, unit);
};

Date.prototype.isSameOrBefore = function (date: ConfigType = new Date(), unit: OpUnitType = 'milliseconds'): boolean {
  return dayjs(this).isSameOrBefore(date, unit);
};

Date.prototype.diff = function (date?: ConfigType, unit?: QUnitType | OpUnitType): number {
  return dayjs(this).diff(date, unit, false);
};

Date.prototype.formatBetweenTime = function formatTimeRange(endTime?: Date): string {
  const start = this.toDayJs();
  if (!endTime) return start.format('LT -');

  const end = endTime.toDayJs();
  const isSameAmPm = start.format('A') === end.format('A');

  const formattedStartTime = isSameAmPm ? start.format('h:mm') : start.format('LT');
  const formattedEndTime = isSameAmPm ? end.format('h:mm A') : end.format('LT');

  return `${formattedStartTime} – ${formattedEndTime}`;
};

export {};
