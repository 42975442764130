export class Campaign {
  id: string;

  type: number;

  name: string;

  startAt: Date;

  endAt: Date;

  description?: string;

  rules?: object;

  status: number;

  meta?: object;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
