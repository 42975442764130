export class PaymentLines {
  id: string;

  orderId: string;

  endOfDateId?: string;

  type: number;

  tenderType: number;

  label?: string;

  grossAmount: number;

  adjustmentAmount: number;

  tipAmount: number;

  taxAmount: number;

  requestAmount: number;

  uniqueCode?: string;

  approvedAmount: number;

  customerId?: string;

  reason?: string;

  relationId?: string;

  cardHolderName?: string;

  billingAddress?: string;

  description?: string;

  status: number;

  paymentLineId?: string;

  meta?: object;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;

  customerName?: string;
}
