export class ApiKey {
  id: string;

  identityId: string;

  key: string;

  roleId?: string;

  validFrom: Date;

  validUntil: Date;

  status: number;

  description?: string;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
