import React, { ReactNode, forwardRef } from 'react';
import { CircularProgress, Button as MUIButton, SxProps, Theme, Typography, ButtonBaseProps } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

export interface ButtonProps {
  buttonText: ReactNode;
  hasBorder?: boolean;
  onClick?: (e?: any) => void;
  isDisabled?: boolean;
  typographySx?: SxProps<Theme>;
  overrideSx?: SxProps<Theme>;
  hasLoadingBar?: boolean;
  loading?: boolean;
  extraButtonProps?: ButtonBaseProps;
}
const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const isDisabled = props?.isDisabled ?? false;
  const getButtonStyleByDisabled = isDisabled ? { border: '1px solid #8F9DA8' } : {};
  const getTypographyStyleByDisabled = isDisabled ? { color: '#8F9DA8' } : {};
  const borderButtonStyle = props.hasBorder ? { border: '1px solid #477797', '&.MuiButtonBase-root': { backgroundColor: 'transparent' } } : {};
  const buttonComponent = props.hasLoadingBar ? (
    <LoadingButton loading={props.loading} loadingIndicator={<CircularProgress color="inherit" size={24} sx={{ zIndex: 5, backgroundColor: '#477797', color: '#FFF' }} />}></LoadingButton>
  ) : (
    <MUIButton></MUIButton>
  );
  const button = React.cloneElement(buttonComponent, {
    children: <Typography sx={{ fontSize: '16px', fontWeight: 400, color: props.hasBorder || (props.hasLoadingBar && props.loading) ? '#477797' : '#FFF', ...getTypographyStyleByDisabled, ...props.typographySx }}>{props.buttonText}</Typography>,
    ref: ref,
    ...props.extraButtonProps,
    disabled: isDisabled,
    sx: {
      display: 'flex',
      height: '58px',
      padding: '16px 32px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '0px',
      gap: '10px',
      '&.MuiButtonBase-root': {
        backgroundColor: isDisabled ? null : '#477797',
      },
      ...{ ...borderButtonStyle, ...getButtonStyleByDisabled, ...props.overrideSx },
    },
    onClick: props.onClick,
  });
  return button;
});

export const BorderButton = (props: ButtonProps & { hasNoBorder?: boolean }) => {
  return <Button {...props} overrideSx={{ ...props.overrideSx }} />;
};

export const StickyButton = (props: ButtonProps) => {
  return <Button {...props} overrideSx={{ position: 'sticky', right: '50px', bottom: '182px', boxShadow: '2px 2px 40px 0px rgba(0, 0, 0, 0.30)', ...props.overrideSx }} />;
};

export default Button;
