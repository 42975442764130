export class BusinessSchedule {
  id: string;

  calendarId: string;

  stationId: string;

  businessStart?: Date;

  businessEnd?: Date;

  description?: string;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
