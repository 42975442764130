import { ProductOnline } from './product_online';
import { CategoryOnline } from './category_online';

export class ProductOnlineMapRelations {
  ProductOnline: ProductOnline;

  RelatedProduct?: ProductOnline;

  CategoryOnline?: CategoryOnline;
}
