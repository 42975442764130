export class ExternalIdentity {
  id: string;

  identityId: string;

  providerName: string;

  providerId: string;

  providerToken: string;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
