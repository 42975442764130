export class Coupon {
  id: string;

  campaignId: string;

  code: string;

  restrictionType: number;

  redemptionLimit: number;

  redeemed: number;

  status: number;

  issuedAt?: Date;

  expireAfterIssuedMinutes: number;

  meta?: object;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
