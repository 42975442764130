export class Product {
  id: string;

  categoryId?: string;

  name: string;

  description?: string;

  gtin?: string;

  brand?: string;

  mpn?: string;

  price: number;

  taxId1?: string;

  taxId2?: string;

  taxId3?: string;

  tipId?: string;

  isCommissionOnRetailSales: boolean;

  isTaxDefault: boolean;

  isCommissionOnRetailSalesDefault: boolean;

  isPrepaidOnly?: boolean;

  tags?: string;

  meta?: object;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
