declare global {
  interface Object {
    removeEmptyOrNull(): any;
    deleteProps(props: any[]): any;
    replaceAll(oldValue: any, newValue: any): any;
    cleanUp<T>(): T;
    removeNullOrUndefined(): void;
  }
}

if (!Object.prototype.removeEmptyOrNull) {
  //Object.prototype.removeEmptyOrNull = () => {};
  // Object.prototype.removeEmptyOrNull = function (): any {
  //   return null;
  // //   return removeEmptyOrNull(this);
  // };
}

const removeEmptyOrNull = (object: Object): Object => {
  Object.keys(object).forEach((k: string) => {
    (object[k] && typeof object[k] === 'object' && removeEmptyOrNull(object[k])) || (!object[k] && object[k] !== undefined && delete object[k]);
  });
  return object;
};

// const replaceJsonValue = (object: Object, oldValue: any, newValue: any): Object => {
//   if (typeof object === 'object') {
//     if (Array.isArray(object)) for (let i = 0; i < object.length; i++) object[i] = object[i].replaceJsonValue(oldValue, newValue);
//     else for (let key in object) object[key] = object[key].replaceJsonValue(oldValue, newValue);
//   } else {
//     if (object === oldValue) return newValue;
//   }
//   return object;
// };

if (!Object.prototype.cleanUp) {
  Object.defineProperty(Object.prototype, 'cleanUp', {
    value: function <T>(): T {
      let obj = this;
      if (typeof obj !== 'object' || obj === null || obj instanceof Date) return obj;
      if (Array.isArray(obj)) return obj.filter(Boolean).map((item: any) => item.cleanUp()) as any;

      const result: any = {};
      for (const key in obj) if (obj.hasOwnProperty(key) && obj[key]) result[key] = obj[key].cleanUp();

      return result;
    },
    writable: false,
    configurable: false,
  });
}

if (!Object.prototype.removeNullOrUndefined) {
  Object.defineProperty(Object.prototype, 'removeNullOrUndefined', {
    value: function (): void {
      if (Array.isArray(this)) {
        for (let i = this.length - 1; i >= 0; i--) {
          if (this[i] === null || this[i] === undefined) {
            this.splice(i, 1);
          } else if (typeof this[i] === 'object') {
            this[i].removeNullOrUndefined();
          }
        }
      } else if (typeof this === 'object' && this !== null) {
        if (Object.keys(this).length === 0) return null;

        for (const key in this) {
          if (this.hasOwnProperty(key)) {
            if (this[key] === null || this[key] === undefined) {
              delete this[key];
            } else if (typeof this[key] === 'object') {
              this[key].removeNullOrUndefined();
            }
          }
        }
      }
    },
    writable: false,
    configurable: false,
  });
}

if (!Object.prototype.replaceAll) {
  Object.defineProperty(Object.prototype, 'replaceAll', {
    value: function (oldValue: any, newValue: any): any {
      let obj = this;
      if (typeof obj === 'object') {
        if (Array.isArray(obj)) {
          for (let i = 0; i < obj.length; i++) {
            obj[i] = obj[i]?.replaceAll(oldValue, newValue);
          }
        } else {
          for (let key in obj) {
            obj[key] = obj[key]?.replaceAll(oldValue, newValue);
          }
        }
      } else {
        if (obj === oldValue) {
          obj = newValue;
        }
      }
      return obj;
    },
    writable: true,
    enumerable: false,
  });
}

// if (!Object.prototype.deleteProps) {
//   Object.prototype.deleteProps = function (props: any[]): any {
//     const newObj = { ...this };
//     props.forEach(prop => {
//       delete newObj[prop];
//     });
//     return newObj;
//   };
// }

export {};
