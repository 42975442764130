export class Timeslot {
  id: string;

  dateslotId: string;

  time: Date;

  capacity: number;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
