import { ApiKeyRelations as _ApiKeyRelations } from './api_key_relations';
import { CalendarRelations as _CalendarRelations } from './calendar_relations';
import { CategoryRelations as _CategoryRelations } from './category_relations';
import { ChannelRelations as _ChannelRelations } from './channel_relations';
import { CustomerRelations as _CustomerRelations } from './customer_relations';
import { CustomerAddressesRelations as _CustomerAddressesRelations } from './customer_addresses_relations';
import { DateslotRelations as _DateslotRelations } from './dateslot_relations';
import { EmployeeRelations as _EmployeeRelations } from './employee_relations';
import { ExternalIdentityRelations as _ExternalIdentityRelations } from './external_identity_relations';
import { GrantedPermissionRelations as _GrantedPermissionRelations } from './granted_permission_relations';
import { HistoryRelations as _HistoryRelations } from './history_relations';
import { IdentityRelations as _IdentityRelations } from './identity_relations';
import { OrderRelations as _OrderRelations } from './order_relations';
import { OrderLinesRelations as _OrderLinesRelations } from './order_lines_relations';
import { OrderShippingRelations as _OrderShippingRelations } from './order_shipping_relations';
import { PaymentLinesRelations as _PaymentLinesRelations } from './payment_lines_relations';
import { PermissionRelations as _PermissionRelations } from './permission_relations';
import { ProductRelations as _ProductRelations } from './product_relations';
import { ProductPriceByTimeslotRelations as _ProductPriceByTimeslotRelations } from './product_price_by_timeslot_relations';
import { ProfileRelations as _ProfileRelations } from './profile_relations';
import { ReservationRelations as _ReservationRelations } from './reservation_relations';
import { RoleRelations as _RoleRelations } from './role_relations';
import { SettingRelations as _SettingRelations } from './setting_relations';
import { TicketRelations as _TicketRelations } from './ticket_relations';
import { TimeslotRelations as _TimeslotRelations } from './timeslot_relations';
import { SequenceRelations as _SequenceRelations } from './sequence_relations';
import { DepartmentRelations as _DepartmentRelations } from './department_relations';
import { NoteRelations as _NoteRelations } from './note_relations';
import { TherapistOrderRelations as _TherapistOrderRelations } from './therapist_order_relations';
import { TherapistScheduleRelations as _TherapistScheduleRelations } from './therapist_schedule_relations';
import { ProductServiceMapRelations as _ProductServiceMapRelations } from './product_service_map_relations';
import { ProductServiceRelations as _ProductServiceRelations } from './product_service_relations';
import { TaxRelations as _TaxRelations } from './tax_relations';
import { RoomRelations as _RoomRelations } from './room_relations';
import { ImagesRelations as _ImagesRelations } from './images_relations';
import { LocationRelations as _LocationRelations } from './location_relations';
import { StationRelations as _StationRelations } from './station_relations';
import { StationMapRelations as _StationMapRelations } from './station_map_relations';
import { AppointmentRelations as _AppointmentRelations } from './appointment_relations';
import { BusinessScheduleRelations as _BusinessScheduleRelations } from './business_schedule_relations';
import { CategoryOnlineRelations as _CategoryOnlineRelations } from './category_online_relations';
import { ProductOnlineRelations as _ProductOnlineRelations } from './product_online_relations';
import { ProductOnlineMapRelations as _ProductOnlineMapRelations } from './product_online_map_relations';
import { OnlineTagsRelations as _OnlineTagsRelations } from './online_tags_relations';
import { CustomerRestrictionsRelations as _CustomerRestrictionsRelations } from './customer_restrictions_relations';
import { ServiceTimeslotsRelations as _ServiceTimeslotsRelations } from './service_timeslots_relations';
import { GiftCertificateRelations as _GiftCertificateRelations } from './gift_certificate_relations';
import { CancelledOrderLinesRelations as _CancelledOrderLinesRelations } from './cancelled_order_lines_relations';
import { RefundedOrderLinesRelations as _RefundedOrderLinesRelations } from './refunded_order_lines_relations';
import { CampaignRelations as _CampaignRelations } from './campaign_relations';
import { CouponRelations as _CouponRelations } from './coupon_relations';
import { CouponRestrictionRelations as _CouponRestrictionRelations } from './coupon_restriction_relations';
import { AvailableTimeslotRelations as _AvailableTimeslotRelations } from './available_timeslot_relations';
import { SearchKeywordRelations as _SearchKeywordRelations } from './search_keyword_relations';
import { CalendarPriceByDayRelations as _CalendarPriceByDayRelations } from './calendar_price_by_day_relations';
import { RecursiveCategoryRelations as _RecursiveCategoryRelations } from './recursive_category_relations';
import { EmployeeScheduleRelations as _EmployeeScheduleRelations } from './employee_schedule_relations';
import { BusinessHourRelations as _BusinessHourRelations } from './business_hour_relations';
import { ProductsByServiceGroupRelations as _ProductsByServiceGroupRelations } from './products_by_service_group_relations';
import { AvailableServiceDateslotsRelations as _AvailableServiceDateslotsRelations } from './available_service_dateslots_relations';
import { RoomByProductServiceRelations as _RoomByProductServiceRelations } from './room_by_product_service_relations';
import { JsonResultRelations as _JsonResultRelations } from './json_result_relations';
import { ApiKey as _ApiKey } from './api_key';
import { Calendar as _Calendar } from './calendar';
import { Category as _Category } from './category';
import { Channel as _Channel } from './channel';
import { Customer as _Customer } from './customer';
import { CustomerAddresses as _CustomerAddresses } from './customer_addresses';
import { Dateslot as _Dateslot } from './dateslot';
import { Employee as _Employee } from './employee';
import { ExternalIdentity as _ExternalIdentity } from './external_identity';
import { GrantedPermission as _GrantedPermission } from './granted_permission';
import { History as _History } from './history';
import { Identity as _Identity } from './identity';
import { Order as _Order } from './order';
import { OrderLines as _OrderLines } from './order_lines';
import { OrderShipping as _OrderShipping } from './order_shipping';
import { PaymentLines as _PaymentLines } from './payment_lines';
import { Permission as _Permission } from './permission';
import { Product as _Product } from './product';
import { ProductPriceByTimeslot as _ProductPriceByTimeslot } from './product_price_by_timeslot';
import { Profile as _Profile } from './profile';
import { Reservation as _Reservation } from './reservation';
import { Role as _Role } from './role';
import { Setting as _Setting } from './setting';
import { Ticket as _Ticket } from './ticket';
import { Timeslot as _Timeslot } from './timeslot';
import { Sequence as _Sequence } from './sequence';
import { Department as _Department } from './department';
import { Note as _Note } from './note';
import { TherapistOrder as _TherapistOrder } from './therapist_order';
import { TherapistSchedule as _TherapistSchedule } from './therapist_schedule';
import { ProductServiceMap as _ProductServiceMap } from './product_service_map';
import { ProductService as _ProductService } from './product_service';
import { Tax as _Tax } from './tax';
import { Room as _Room } from './room';
import { Images as _Images } from './images';
import { Location as _Location } from './location';
import { Station as _Station } from './station';
import { StationMap as _StationMap } from './station_map';
import { Appointment as _Appointment } from './appointment';
import { BusinessSchedule as _BusinessSchedule } from './business_schedule';
import { CategoryOnline as _CategoryOnline } from './category_online';
import { ProductOnline as _ProductOnline } from './product_online';
import { ProductOnlineMap as _ProductOnlineMap } from './product_online_map';
import { OnlineTags as _OnlineTags } from './online_tags';
import { CustomerRestrictions as _CustomerRestrictions } from './customer_restrictions';
import { ServiceTimeslots as _ServiceTimeslots } from './service_timeslots';
import { GiftCertificate as _GiftCertificate } from './gift_certificate';
import { CancelledOrderLines as _CancelledOrderLines } from './cancelled_order_lines';
import { RefundedOrderLines as _RefundedOrderLines } from './refunded_order_lines';
import { Campaign as _Campaign } from './campaign';
import { Coupon as _Coupon } from './coupon';
import { CouponRestriction as _CouponRestriction } from './coupon_restriction';
import { AvailableTimeslot as _AvailableTimeslot } from './available_timeslot';
import { SearchKeyword as _SearchKeyword } from './search_keyword';
import { CalendarPriceByDay as _CalendarPriceByDay } from './calendar_price_by_day';
import { RecursiveCategory as _RecursiveCategory } from './recursive_category';
import { EmployeeSchedule as _EmployeeSchedule } from './employee_schedule';
import { BusinessHour as _BusinessHour } from './business_hour';
import { ProductsByServiceGroup as _ProductsByServiceGroup } from './products_by_service_group';
import { AvailableServiceDateslots as _AvailableServiceDateslots } from './available_service_dateslots';
import { RoomByProductService as _RoomByProductService } from './room_by_product_service';
import { JsonResult as _JsonResult } from './json_result';

export namespace PrismaModel {
  export class ApiKeyRelations extends _ApiKeyRelations {}
  export class CalendarRelations extends _CalendarRelations {}
  export class CategoryRelations extends _CategoryRelations {}
  export class ChannelRelations extends _ChannelRelations {}
  export class CustomerRelations extends _CustomerRelations {}
  export class CustomerAddressesRelations extends _CustomerAddressesRelations {}
  export class DateslotRelations extends _DateslotRelations {}
  export class EmployeeRelations extends _EmployeeRelations {}
  export class ExternalIdentityRelations extends _ExternalIdentityRelations {}
  export class GrantedPermissionRelations extends _GrantedPermissionRelations {}
  export class HistoryRelations extends _HistoryRelations {}
  export class IdentityRelations extends _IdentityRelations {}
  export class OrderRelations extends _OrderRelations {}
  export class OrderLinesRelations extends _OrderLinesRelations {}
  export class OrderShippingRelations extends _OrderShippingRelations {}
  export class PaymentLinesRelations extends _PaymentLinesRelations {}
  export class PermissionRelations extends _PermissionRelations {}
  export class ProductRelations extends _ProductRelations {}
  export class ProductPriceByTimeslotRelations extends _ProductPriceByTimeslotRelations {}
  export class ProfileRelations extends _ProfileRelations {}
  export class ReservationRelations extends _ReservationRelations {}
  export class RoleRelations extends _RoleRelations {}
  export class SettingRelations extends _SettingRelations {}
  export class TicketRelations extends _TicketRelations {}
  export class TimeslotRelations extends _TimeslotRelations {}
  export class SequenceRelations extends _SequenceRelations {}
  export class DepartmentRelations extends _DepartmentRelations {}
  export class NoteRelations extends _NoteRelations {}
  export class TherapistOrderRelations extends _TherapistOrderRelations {}
  export class TherapistScheduleRelations extends _TherapistScheduleRelations {}
  export class ProductServiceMapRelations extends _ProductServiceMapRelations {}
  export class ProductServiceRelations extends _ProductServiceRelations {}
  export class TaxRelations extends _TaxRelations {}
  export class RoomRelations extends _RoomRelations {}
  export class ImagesRelations extends _ImagesRelations {}
  export class LocationRelations extends _LocationRelations {}
  export class StationRelations extends _StationRelations {}
  export class StationMapRelations extends _StationMapRelations {}
  export class AppointmentRelations extends _AppointmentRelations {}
  export class BusinessScheduleRelations extends _BusinessScheduleRelations {}
  export class CategoryOnlineRelations extends _CategoryOnlineRelations {}
  export class ProductOnlineRelations extends _ProductOnlineRelations {}
  export class ProductOnlineMapRelations extends _ProductOnlineMapRelations {}
  export class OnlineTagsRelations extends _OnlineTagsRelations {}
  export class CustomerRestrictionsRelations extends _CustomerRestrictionsRelations {}
  export class ServiceTimeslotsRelations extends _ServiceTimeslotsRelations {}
  export class GiftCertificateRelations extends _GiftCertificateRelations {}
  export class CancelledOrderLinesRelations extends _CancelledOrderLinesRelations {}
  export class RefundedOrderLinesRelations extends _RefundedOrderLinesRelations {}
  export class CampaignRelations extends _CampaignRelations {}
  export class CouponRelations extends _CouponRelations {}
  export class CouponRestrictionRelations extends _CouponRestrictionRelations {}
  export class AvailableTimeslotRelations extends _AvailableTimeslotRelations {}
  export class SearchKeywordRelations extends _SearchKeywordRelations {}
  export class CalendarPriceByDayRelations extends _CalendarPriceByDayRelations {}
  export class RecursiveCategoryRelations extends _RecursiveCategoryRelations {}
  export class EmployeeScheduleRelations extends _EmployeeScheduleRelations {}
  export class BusinessHourRelations extends _BusinessHourRelations {}
  export class ProductsByServiceGroupRelations extends _ProductsByServiceGroupRelations {}
  export class AvailableServiceDateslotsRelations extends _AvailableServiceDateslotsRelations {}
  export class RoomByProductServiceRelations extends _RoomByProductServiceRelations {}
  export class JsonResultRelations extends _JsonResultRelations {}
  export class ApiKey extends _ApiKey {}
  export class Calendar extends _Calendar {}
  export class Category extends _Category {}
  export class Channel extends _Channel {}
  export class Customer extends _Customer {}
  export class CustomerAddresses extends _CustomerAddresses {}
  export class Dateslot extends _Dateslot {}
  export class Employee extends _Employee {}
  export class ExternalIdentity extends _ExternalIdentity {}
  export class GrantedPermission extends _GrantedPermission {}
  export class History extends _History {}
  export class Identity extends _Identity {}
  export class Order extends _Order {}
  export class OrderLines extends _OrderLines {}
  export class OrderShipping extends _OrderShipping {}
  export class PaymentLines extends _PaymentLines {}
  export class Permission extends _Permission {}
  export class Product extends _Product {}
  export class ProductPriceByTimeslot extends _ProductPriceByTimeslot {}
  export class Profile extends _Profile {}
  export class Reservation extends _Reservation {}
  export class Role extends _Role {}
  export class Setting extends _Setting {}
  export class Ticket extends _Ticket {}
  export class Timeslot extends _Timeslot {}
  export class Sequence extends _Sequence {}
  export class Department extends _Department {}
  export class Note extends _Note {}
  export class TherapistOrder extends _TherapistOrder {}
  export class TherapistSchedule extends _TherapistSchedule {}
  export class ProductServiceMap extends _ProductServiceMap {}
  export class ProductService extends _ProductService {}
  export class Tax extends _Tax {}
  export class Room extends _Room {}
  export class Images extends _Images {}
  export class Location extends _Location {}
  export class Station extends _Station {}
  export class StationMap extends _StationMap {}
  export class Appointment extends _Appointment {}
  export class BusinessSchedule extends _BusinessSchedule {}
  export class CategoryOnline extends _CategoryOnline {}
  export class ProductOnline extends _ProductOnline {}
  export class ProductOnlineMap extends _ProductOnlineMap {}
  export class OnlineTags extends _OnlineTags {}
  export class CustomerRestrictions extends _CustomerRestrictions {}
  export class ServiceTimeslots extends _ServiceTimeslots {}
  export class GiftCertificate extends _GiftCertificate {}
  export class CancelledOrderLines extends _CancelledOrderLines {}
  export class RefundedOrderLines extends _RefundedOrderLines {}
  export class Campaign extends _Campaign {}
  export class Coupon extends _Coupon {}
  export class CouponRestriction extends _CouponRestriction {}
  export class AvailableTimeslot extends _AvailableTimeslot {}
  export class SearchKeyword extends _SearchKeyword {}
  export class CalendarPriceByDay extends _CalendarPriceByDay {}
  export class RecursiveCategory extends _RecursiveCategory {}
  export class EmployeeSchedule extends _EmployeeSchedule {}
  export class BusinessHour extends _BusinessHour {}
  export class ProductsByServiceGroup extends _ProductsByServiceGroup {}
  export class AvailableServiceDateslots extends _AvailableServiceDateslots {}
  export class RoomByProductService extends _RoomByProductService {}
  export class JsonResult extends _JsonResult {}

  export const extraModels = [
    ApiKeyRelations,
    CalendarRelations,
    CategoryRelations,
    ChannelRelations,
    CustomerRelations,
    CustomerAddressesRelations,
    DateslotRelations,
    EmployeeRelations,
    ExternalIdentityRelations,
    GrantedPermissionRelations,
    HistoryRelations,
    IdentityRelations,
    OrderRelations,
    OrderLinesRelations,
    OrderShippingRelations,
    PaymentLinesRelations,
    PermissionRelations,
    ProductRelations,
    ProductPriceByTimeslotRelations,
    ProfileRelations,
    ReservationRelations,
    RoleRelations,
    SettingRelations,
    TicketRelations,
    TimeslotRelations,
    SequenceRelations,
    DepartmentRelations,
    NoteRelations,
    TherapistOrderRelations,
    TherapistScheduleRelations,
    ProductServiceMapRelations,
    ProductServiceRelations,
    TaxRelations,
    RoomRelations,
    ImagesRelations,
    LocationRelations,
    StationRelations,
    StationMapRelations,
    AppointmentRelations,
    BusinessScheduleRelations,
    CategoryOnlineRelations,
    ProductOnlineRelations,
    ProductOnlineMapRelations,
    OnlineTagsRelations,
    CustomerRestrictionsRelations,
    ServiceTimeslotsRelations,
    GiftCertificateRelations,
    CancelledOrderLinesRelations,
    RefundedOrderLinesRelations,
    CampaignRelations,
    CouponRelations,
    CouponRestrictionRelations,
    AvailableTimeslotRelations,
    SearchKeywordRelations,
    CalendarPriceByDayRelations,
    RecursiveCategoryRelations,
    EmployeeScheduleRelations,
    BusinessHourRelations,
    ProductsByServiceGroupRelations,
    AvailableServiceDateslotsRelations,
    RoomByProductServiceRelations,
    JsonResultRelations,
    ApiKey,
    Calendar,
    Category,
    Channel,
    Customer,
    CustomerAddresses,
    Dateslot,
    Employee,
    ExternalIdentity,
    GrantedPermission,
    History,
    Identity,
    Order,
    OrderLines,
    OrderShipping,
    PaymentLines,
    Permission,
    Product,
    ProductPriceByTimeslot,
    Profile,
    Reservation,
    Role,
    Setting,
    Ticket,
    Timeslot,
    Sequence,
    Department,
    Note,
    TherapistOrder,
    TherapistSchedule,
    ProductServiceMap,
    ProductService,
    Tax,
    Room,
    Images,
    Location,
    Station,
    StationMap,
    Appointment,
    BusinessSchedule,
    CategoryOnline,
    ProductOnline,
    ProductOnlineMap,
    OnlineTags,
    CustomerRestrictions,
    ServiceTimeslots,
    GiftCertificate,
    CancelledOrderLines,
    RefundedOrderLines,
    Campaign,
    Coupon,
    CouponRestriction,
    AvailableTimeslot,
    SearchKeyword,
    CalendarPriceByDay,
    RecursiveCategory,
    EmployeeSchedule,
    BusinessHour,
    ProductsByServiceGroup,
    AvailableServiceDateslots,
    RoomByProductService,
    JsonResult,
  ];
}
