import { Calendar } from './calendar';
import { Employee } from './employee';
import { TherapistSchedule } from './therapist_schedule';

export class TherapistOrderRelations {
  Calendar: Calendar;

  Employee: Employee;

  TherapistSchedule: TherapistSchedule;
}
