export class OrderLines {
  id: string;

  orderId: string;

  endOfDateId?: string;

  sequence?: number;

  categoryId: string;

  productId: string;

  customerId?: string;

  orderShippingId?: string;

  salesRepresentativeId?: string;

  appointmentId?: string;

  listPrice: number;

  sellingPrice: number;

  tax1Amount: number;

  tax2Amount: number;

  extraChargeAmount: number;

  tip1Amount: number;

  tip2Amount: number;

  discount1Amount: number;

  discount2Amount: number;

  discount3Amount: number;

  promotionAmount: number;

  totalAmount: number;

  refundAmount: number;

  status: number;

  orderLineId?: string;

  description?: string;

  meta?: object;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;

  productType: number;

  productName?: string;
}
