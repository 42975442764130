import { RecursiveCategory } from './recursive_category';
import { ProductOnlineMap } from './product_online_map';
import { OnlineTags } from './online_tags';

export class CategoryOnlineRelations {
  RecursiveCategory?: RecursiveCategory;

  ProductOnlineMaps: ProductOnlineMap[];

  OnlineTags: OnlineTags[];
}
