import { Location } from './location';
import { BusinessSchedule } from './business_schedule';
import { BusinessHour } from './business_hour';
import { StationMap } from './station_map';
import { ProductsByServiceGroup } from './products_by_service_group';

export class StationRelations {
  Location: Location;

  BusinessSchedules: BusinessSchedule[];

  BusinessHours: BusinessHour[];

  StationMaps: StationMap[];

  ProductsByServiceGroups: ProductsByServiceGroup[];
}
