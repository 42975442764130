declare global {
  interface Promise<T> {
    thenIfTrue(callback: (value: T) => Promise<void>): Promise<T>;
    thenIfFalse(callback: (value: T) => Promise<void>): Promise<T>;
  }
}

export {};

// Promise에 thenIf 메서드를 구현하는 함수
function extendPromisePrototype() {
  Promise.prototype.thenIfTrue = async function <T>(this: Promise<T>, callback: (value: T) => Promise<void>): Promise<T> {
    const value = await this;
    if (Boolean(value) === true) await callback(value);

    return value;
  };

  Promise.prototype.thenIfFalse = async function <T>(this: Promise<T>, callback: (value: T) => Promise<void>): Promise<T> {
    const value = await this;
    if (Boolean(value) === false) await callback(value);

    return value;
  };
}

// Promise 프로토타입 확장 함수 호출
extendPromisePrototype();
