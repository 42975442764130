import * as yup from 'yup';
import { emailValidate, nameRegex, phoneRegex, passwordRegex, treatmentNameRegex } from './regex';
import valid from 'card-validator';

export interface PasswordFormData {
  password: string;
  confirmPassword: string;
}

export const field = async (schema: yup.AnySchema, fieldName: string, value: any) => {
  return await schema
    .validateAt(fieldName, { [fieldName]: value })
    .then(() => undefined)
    .catch(e => e.message);
};

export const objectField = <T>(schema: yup.ObjectSchema<T>, fieldName: string) => {
  return { validate: async (value: any) => await field(schema, fieldName, value) };
};
export const firstNameSchema = (schema: yup.StringSchema<string, yup.AnyObject, undefined, ''>) =>
  schema
    .trim()
    .min(2, 'Invalid entry.')
    .max(64)
    .required('Invalid entry.')
    .test('name', 'Invalid entry.', value => nameRegex.test(value));
export const lastNameSchema = (schema: yup.StringSchema<string, yup.AnyObject, undefined, ''>) =>
  schema
    .trim()
    .min(2, 'Invalid entry.')
    .max(64)
    .required('Invalid entry.')
    .test('name', 'Invalid entry.', value => nameRegex.test(value));
export const treatmentFirstNameSchema = (schema: yup.StringSchema<string, yup.AnyObject, undefined, ''>) =>
  schema
    .trim()
    .min(1, 'Invalid entry.')
    .max(64)
    .required('Invalid entry.')
    .test('name', 'Invalid entry.', value => treatmentNameRegex.test(value));

export const treatmentLastNameSchema = (schema: yup.StringSchema<string, yup.AnyObject, undefined, ''>) =>
  schema
    .trim()
    .min(1, 'Invalid entry.')
    .max(64)
    .required('Invalid entry.')
    .test('name', 'Invalid entry.', value => treatmentNameRegex.test(value));

export const passwordSchema = yup.string().min(10).required();

const invalidNumbers = [  '0000000000',  '1111111111',  '2222222222',  '3333333333',  '4444444444',  '5555555555',  '6666666666',  '7777777777',  '8888888888',  '9999999999'];
export const phoneSchema = yup.string().required('Please enter your phone.').min(10, 'Enter the phone this way: 1234567890').matches(phoneRegex, 'Please enter a valid 10 digit phone number.').test('no-repeated-digits', 'Please enter a valid phone number.', (value) => !invalidNumbers.includes(value));

export const emailSchema = yup.string().max(128).email().matches(emailValidate, 'Please enter the correct email format').required();
export const emailConfirmationSchema = yup
  .string()
  .max(128)
  .email('Enter the email address this way: name@email.com')
  .required('Please enter your email address.')
  .test('match', 'Your email address does not match.', function (emailConfirmation) {
    return emailConfirmation.toLowerCase() === this.parent.email.toLowerCase();
  });

export const resetPasswordSchema = {
  password: yup.string().min(10, 'Password must be at least 10 characters long').matches(passwordRegex, 'Password must include at least one uppercase letter, one lowercase letter, one number, and one symbol excluding * &  " ; | < > =').required(),
  confirmPassword: yup
    .string()
    .min(10, 'Password must be at least 10 characters long')
    .required()
    .test('password-should-match', 'Passwords should match, please confirm your password', function (confirmPassword) {
      return confirmPassword?.toLowerCase() === this.parent.password?.toLowerCase();
    }),
};

export const paymentSchema = yup.object().shape({
  cardHolderName: yup
    .string()
    .matches(/\b.*[a-zA-Z]+.*\b/, 'Please enter cardholder name.')
    .max(64)
    .required('Please enter cardholder name'),
  creditCard: yup
    .string()
    .test('test-number', 'Check the credit card info and try again.', value => valid.number(value).isValid)
    .required('Please enter your card number.'),
  expiry: yup
    .string()
    .typeError('Please enter your expiration date')
    .max(5, 'Please enter your expiration date')
    .matches(/^(0[0-9]|1[0-2])\/?([0-9]{2})$/, 'Please enter valid expiration date')
    .required('Please enter your expiration date'),
  cvc: yup.string().max(4).required('Please enter your security code.'),
  address: yup.string().max(128).required('Please enter your street address.'),
  city: yup.string().max(64).required('Please enter your town / city.'),
  state: yup.string().required('Please enter your state.'),
  zipCode: yup.string().max(10).required('Please enter your zip.'),
});
