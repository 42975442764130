export class CustomerRestrictions {
  id: string;

  keyword: string;

  legacyId?: string;

  restrictionType: number;

  note?: string;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
