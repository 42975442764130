export class ServiceTimeslots {
  id: string;

  businessScheduleId: string;

  time: Date;

  type: number;

  totalSingleRooms: number;

  totalGroupRooms: number;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
