export class Reservation {
  id: string;

  productId: string;

  dateslotId: string;

  timeslotId: string;

  numberOfGuest: number;

  orderId?: string;

  status: number;

  refs?: string;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
