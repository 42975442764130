declare global {
  interface Number {
    /**
     * Decimal형태의 금액을 Fixed-point arithmetic으로 변경.
     *
     * @example
     * toCurrency(9.99) => 999
     */
    toCurrency(): number;

    /**
     * Fixed-point arithmetic의 금액을 Decimal로 변경.
     *
     * @example
     * fromCurrency(999) => 9.99
     */
    fromCurrency(): number;

    /**
     * Return formatted string of tax rate from number.
     */
    toTaxRate(decimalPoint?: number): string;

    toTaxRateFormat(decimalPoint?: number): string;

    /**
     * Return decimal formatted string of price.
     */
    toPriceString(showNegativeForPositive?: boolean): string;

    /**
     * Return decimal formatted string of negative price.
     */
    toNegativePriceString(): string;

    /**
     * Return 'X hr XX mins' formatted string from seconds.
     *
     * @example
     * toTimeStringFromSeconds(3660) => '1hr 1min'
     */
    toTimeStringFromMinutes(): string;

    parseEnumValue<T>(enumType: T): keyof T | undefined;
  }
}

if (!Number.prototype.toCurrency) {
  Number.prototype.toCurrency = function (): number {
    return this * 100;
  };
}

if (!Number.prototype.fromCurrency) {
  Number.prototype.fromCurrency = function (): number {
    return this >= 100 ? this / 100 : this;
  };
}

/* TAX */
if (!Number.prototype.toTaxRate) {
  Number.prototype.toTaxRate = function (decimalPoint?: number): string {
    const decimal = decimalPoint ?? 3;
    if (this) {
      return (this / 1000).toFixed(decimal);
    }
    return '0.'.padEnd(decimal + 2, '0');
  };
}

if (!Number.prototype.toTaxRateFormat) {
  Number.prototype.toTaxRateFormat = function (decimalPoint?: number): string {
    return `${this.toTaxRate(decimalPoint)}%`;
  };
}

if (!Number.prototype.toPriceString) {
  Number.prototype.toPriceString = function (showNegativeForPositive: boolean = false): string {
    if (this !== null && this !== undefined) {
      const priceString = '';
      const isNegative = this < 0 || (showNegativeForPositive && this > 0);
      const absoluteValue = Math.abs(this);
      const number = Math.floor(absoluteValue / 100);
      const decimalNumber = absoluteValue % 100;
      const numberWithCommas = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const formattedPrice = priceString.concat(numberWithCommas, '.', decimalNumber.toString().padStart(2, '0'));
      return isNegative ? `-$${formattedPrice}` : `$${formattedPrice}`;
    }
    return '$0.00';
  };
}

if (!Number.prototype.toNegativePriceString) {
  Number.prototype.toNegativePriceString = function (): string {
    return this.toPriceString(true);
  };
}

if (!Number.prototype.toTimeStringFromMinutes) {
  Number.prototype.toTimeStringFromMinutes = function (): string {
    const timeString = '';
    if (this) {
      const hours = Math.floor(this / 60);
      const hourString = hours === 0 ? '' : `${hours} hr `;
      const minutes = Math.floor(this % 60);
      const minuteString = minutes === 0 ? '' : `${minutes} min`;

      return timeString.concat(hourString, minuteString) ?? '';
    }
    return '';
  };
}

if (!Number.prototype.parseEnumValue) {
  Number.prototype.parseEnumValue = function <T>(enumType: T): keyof T | undefined {
    const keys = Object.keys(enumType).filter(key => typeof enumType[key as keyof T] === 'number');
    for (const key of keys) {
      if (enumType[key] === this.valueOf()) {
        return key as keyof T;
      }
    }
    return undefined;
  };
}

export {};
