export class CalendarPriceByDay {
  dateslotId?: string;

  minimumPrice?: number;

  maximumPrice?: number;

  date: Date;

  holidayText?: string;

  available?: number;
}
