export class CancelledOrderLines {
  id: string;

  orderId: string;

  orderLineId: string;

  endOfDateId?: string;

  feeAmount: number;

  reason?: string;

  meta?: object;

  isWaived: boolean;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
