import { Box, Divider, List, ListItem, SxProps, Typography, styled } from '@mui/material';
import React, { Fragment, ReactNode } from 'react';

export const NoticeTypography = styled(Typography)({
  color: '#657179',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '160%',
}) as typeof Typography;

export const BoldNoticeTypography = styled(NoticeTypography)({
  display: 'inline',
  fontWeight: 600,
}) as typeof Typography;

interface NoticeProps {
  icon: React.ReactSVGElement | React.ReactElement;
  title: string;
  descriptionList: ReactNode[];
}
const NoticeRow = (props: NoticeProps & { titleOverrideStyle?: SxProps }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', alignSelf: 'stretch' }}>
      {props.icon && React.cloneElement(props.icon, { width: 80, height: 80 })}
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: '8px', flex: '1 0 0' }}>
        <Typography sx={{ color: '#333', fontFamily: 'Inter', fontSize: '18px', fontStyle: 'normal', fontWeight: '700', lineHeight: '114%', ...props.titleOverrideStyle }}>{props.title}</Typography>
        <List sx={{ listStyleType: 'disc', ml: '15px', p: 0 }}>
          {props.descriptionList.map((description, index) => (
            <ListItem key={`${props.title}_${index}`} sx={{ display: 'list-item', m: 0, p: 0, listStylePosition: 'outside' }}>
              {React.isValidElement(description) ? description : <NoticeTypography display={'inline'}>{description}</NoticeTypography>}
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};
export const noticeList: NoticeProps[] = [
  {
    icon: <img src={'https://image.email.sojospaclub.com/lib/fe331173716404797d1070/m/1/ab207d95-7e54-49de-b9d4-1d149a40a1b4.png'} height="80" width="80" alt={'When to Arrive'} />,
    title: 'When to Arrive',
    descriptionList: ['Arrive at least 60 minutes before your treatment for a stress-free preparation experience', 'Late arrivals may result in a reduction of treatment time'],
  },
  {
    icon: (
      <img
        src="https://image.email.sojospaclub.com/lib/fe331173716404797d1070/m/1/b5abf3be-09ff-4058-841c-05e0a9deb7f5.png"
        alt="What to Bring"
        height="80"
        width="80"
        data-bit="iit"
        style={{
          display: 'block',
          padding: '0px',
          textAlign: 'center',
          border: '0px',
        }}
      />
    ),
    title: 'What to Bring',
    descriptionList: [
      <NoticeTypography component="span" display={'inline'}>
        <BoldNoticeTypography component="span">Valid, physical photo ID</BoldNoticeTypography>: Required to enter SoJo Spa Club, both for your treatment and amenity access
      </NoticeTypography>,
      <NoticeTypography component="span" display={'inline'}>
        <BoldNoticeTypography component="span">Swimwear</BoldNoticeTypography>: For access to showers, pools, and baths
      </NoticeTypography>,
    ],
  },
  {
    icon: (
      <img
        src="https://image.email.sojospaclub.com/lib/fe331173716404797d1070/m/1/f402f62b-41c3-4f94-8fd5-f9a2751442c7.png"
        alt=""
        height="80"
        width="80"
        style={{ display: 'block', padding: '0px', textAlign: 'center', border: '0px' }}
        data-bit="iit"
      />
    ),
    title: 'Paying with Gift Card',
    descriptionList: ['Original gift card required for redemption', 'Pictures or order confirmations/receipts not accepted', 'E-gift cards take 24 hours to become active'],
  },
  {
    icon: <img src={'https://image.email.sojospaclub.com/lib/fe331173716404797d1070/m/1/b0764129-e762-43dc-988b-a1c352a5e453.png'} height="80" width="80" alt={'Spa Admission Discount'} />,
    title: 'Spa Admission Discount',
    descriptionList: [
      <NoticeTypography component="span" display={'inline'}>
        Treatments over $215 per person could enjoy <BoldNoticeTypography component="span">2 hours of amenities</BoldNoticeTypography> post-service and <BoldNoticeTypography component="span">complimentary valet parking</BoldNoticeTypography>
      </NoticeTypography>,
      <NoticeTypography component="span" display={'inline'}>
        Eligible for <BoldNoticeTypography component="span">50% discount</BoldNoticeTypography> on our daily admission cost to stay longer than 2 hours
      </NoticeTypography>,
      <NoticeTypography component="span" display={'inline'}>
        Does not apply to Korean Body Scrubs
      </NoticeTypography>,
    ],
  },
];
export const QnaNoticeList = (props: { hasImage: boolean; excludeNoticeIndex?: number[]; overrideContainerBoxSx?: SxProps; titleOverrideStyle?: SxProps }) => {
  const notices = React.useMemo(() => noticeList.filter((_, index) => !props.excludeNoticeIndex?.includes(index)), [props.excludeNoticeIndex]);
  if (!props.hasImage) notices.forEach(notice => (notice.icon = null));
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '24px', alignItems: 'center', gap: '8px', alignSelf: 'stretch', borderRadius: '8px', border: '1px solid #D3D6D9', background: '#FFF', ...props.overrideContainerBoxSx }}>
      {notices?.map((notice, index) => {
        return (
          <Fragment key={`notice-${index}`}>
            <NoticeRow key={`${notice.title}_${index}`} {...notice} titleOverrideStyle={props.titleOverrideStyle} />
            {index !== noticeList.length - 1 && <Divider sx={{ width: '100%', paddingTop: '16px', marginBottom: '8px', color: '#D3D6D9' }} />}
          </Fragment>
        );
      })}
    </Box>
  );
};

export default QnaNoticeList;
