export class OnlineTags {
  id: string;

  relationId: string;

  tag?: string;

  type: number;

  sequence: number;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
