export class ProductOnlineMap {
  id: string;

  type: number;

  onlineProductId: string;

  relationId: string;

  sequence: number;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
