import { IncomingHttpHeaders } from 'http';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GetServerSidePropsContext, PreviewData } from 'next';
import { ParsedUrlQuery } from 'querystring';

export const stringifyIfArray = (value: string | string[]) => {
    if (Array.isArray(value)) {
      if (value.length > 0) return value.join(', ');
      else return null;
    }
    return value;
  };
  
  interface RequestInfo {
    headers: IncomingHttpHeaders;
  }
  
export const getEdgeRealIp = (requestInfo: RequestInfo): string | null => {
    try {
      const { headers } = requestInfo;
  
      //* In order to collect client IPv6 on header controlled by Cloudflare DNS proxied
      const cfConnectingIP = stringifyIfArray(headers['cf-connecting-ip']); 
      if (cfConnectingIP) return cfConnectingIP;
  
      const xForwardedFor = stringifyIfArray(headers['x-forwarded-for']);
      if (xForwardedFor) return xForwardedFor;
  
      const xRealIp = stringifyIfArray(headers['x-real-ip']);
      if (xRealIp) return xRealIp;
  
      return null;
    } catch (error) {
      console.log('Error getting forwarded IP:', error);
      return null;
    }
};


type ClientRequestHeaderState = {
  edgeRealIp: string | null;
  cookies: string;
  userAgent: string;
  referer: string;
};

const clientRequestHeaderSlice = createSlice({
  name: 'clientRequestHeader',
  initialState: { edgeRealIp: null, cookies: null, userAgent: null, referer: null } as ClientRequestHeaderState,
  reducers: {
    setClientRequestHeader(state, action: PayloadAction<GetServerSidePropsContext<ParsedUrlQuery, PreviewData>>) {
      try {
        if (!action?.payload?.req) return;
        const { req } = action.payload;
        const { headers } = req;
        const edgeRealIp = getEdgeRealIp(req);
        const cookieString = Object.entries(req.cookies)
          .map(([key, value]) => `${key}=${value}`)
          .join('; ');

        state.edgeRealIp = edgeRealIp || null;
        state.cookies = cookieString || null;
        state.userAgent = headers['user-agent'] || null;
        state.referer = headers['referer'] || null;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    resetClientRequestHeader(state) {
      state.edgeRealIp = null;
      state.cookies = null;
      state.userAgent = null;
      state.referer = null;
    },
  },
});
export const { setClientRequestHeader, resetClientRequestHeader } = clientRequestHeaderSlice.actions;
export const clientRequestHeaderReducer = clientRequestHeaderSlice.reducer;

