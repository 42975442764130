import { Typography as MUITypography, TypographyOwnProps, TypographyTypeMap } from '@mui/material';
import { SxProps, TypographyProps, styled } from '@mui/system';
import { CSSProperties, ReactNode } from 'react';

type TypographyType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'bodyBase' | 'body2' | 'subtitle1' | 'subtitle2' | 'caption' | 'helperText' | 'bodySmall' | 'bodyLarge' | 'extraSmall';
type TypographyTypeObj = {
  [key in TypographyType]: CSSProperties;
};
type TypographyColor = 'primary' | 'primaryDark' | 'lightTextPrimary' | 'lightTextDisabled' | 'secondary' | 'lightTextSecondary' | 'link' | 'black' | 'lightPrimaryMain' | 'lightPrimaryLight' | 'lightPrimaryDark';
const typographyColors: { [key in TypographyColor]: string } = {
  primary: '#667080',
  lightTextPrimary: 'rgba(76, 78, 100, 0.87)',
  lightTextDisabled: 'rgba(76, 78, 100, 0.38)',
  primaryDark: '#333',

  secondary: '#657179',
  lightTextSecondary: 'rgba(76, 78, 100, 0.68)',

  link: '#477797',
  black: '#000',

  lightPrimaryMain: '#666CFF',
  lightPrimaryLight: '#787EFF',
  lightPrimaryDark: '#5A5FE0',
};

const typographyTags: TypographyTypeObj = {
  h1: {
    fontSize: '96px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '112.03px',
    letterSpacing: '-1.5px',
  },
  h2: {
    fontSize: '60px',
    fontWeight: 500,
    lineHeight: '72px',
    letterSpacing: '-0.5px',
  },
  h3: {
    fontSize: '48px',
    fontWeight: 500,
    lineHeight: '56px',
  },
  h4: {
    fontSize: '32px',
    fontWeight: 500,
    lineHeight: '40px',
    letterSpacing: '0.25px',
  },
  h5: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '133.4%',
  },
  h6: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '32px',
    letterSpacing: '0.15px',
  },
  body1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  bodyBase: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '21.6px',
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.15px',
  },
  subtitle1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '175%',
    letterSpacing: '0.15px',
  },
  subtitle2: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '157%',
    letterSpacing: '0.1px',
  },
  caption: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0.4px',
  },
  helperText: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.4px',
  },
  bodySmall: {
    fontSize: '14px',
    fontWeight: 400,
  },
  bodyLarge: {
    fontSize: '18px',
    fontWeight: 400,
  },
  extraSmall: {
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '17px',
  },
};

export type TTypographyProps = {
  tag: TypographyType;
  semiBold?: boolean;
  color?: TypographyColor;
  children?: ReactNode;
} & TypographyOwnProps;
export const Typography = (props: TTypographyProps) => {
  return (
    <MUITypography
      {...props}
      sx={{
        fontFamily: props.fontFamily ?? 'Inter',
        fontStyle: 'normal',
        letterSpacing: '0.15px',
        color: typographyColors[props.color],
        ...typographyTags[props.tag],
        ...props.sx,
      }}
    >
      {props.children}
    </MUITypography>
  );
};

export const BodyBaseTypography = styled(MUITypography)({
  color: '#657179',
  fontFamily: 'Inter', //'Kaisei HarunoUmi',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '21.6px',
}) as typeof MUITypography;

export const H4Typography = styled(MUITypography)({
  color: '#333',
  fontFamily: 'Kaisei HarunoUmi',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
});
export const H5Typography = styled(H4Typography)({
  fontSize: '20px',
});
export const BodyTypography = styled(MUITypography)({
  color: '#333',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '21.6px',
});

export const HelperTextTypography = styled(MUITypography)({
  color: '#657179',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '17px',
});

export const BodySmallTypography = styled(BodyBaseTypography)({
  fontSize: '14px',
  fontWeight: 400,
});
export const BodyLargeTypography = styled(BodyBaseTypography)({
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '400',
});

export default BodyBaseTypography;

// file 정의된 style 파악
// file내에 사용되는 typography style에
