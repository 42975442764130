import { Station } from './station';
import { Role } from './role';
import { Room } from './room';

export class StationMapRelations {
  Station: Station;

  Role: Role;

  Room: Room;
}
