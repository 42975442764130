import { ProductService } from './product_service';
import { Product } from './product';
import { GiftCertificate } from './gift_certificate';

export class ProductServiceMapRelations {
  ProductService: ProductService;

  Product: Product;

  GiftCertificate?: GiftCertificate;
}
