export class Employee {
  id: string;

  profileId: string;

  departmentId?: string;

  roleId?: string;

  phoneNumber?: string;

  employeeNumber?: string;

  employeeCardNumber?: string;

  isCommissionOnRetailSales: boolean;

  isCommissionOnServices: boolean;

  isServiceDefault: boolean;

  isCommissionOnRetailSalesDefault: boolean;

  isCommissionOnServicesDefault: boolean;

  tags?: string;

  meta?: object;

  validFrom?: Date;

  validUntil?: Date;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
