import { SpaPromotion, TPromotionConfig, PromotionType, PromotionTypeValue, PROMOTION_TYPES } from '@edge/type';
import { getDay, format, parse, set, isWithinInterval } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import '@edge/common';
import { useMemo } from 'react';

const timeZone = 'America/New_York';

export const useSpaPromotion = <TServiceGroupDto extends { name?: string; products?: TProduct[] }, TProduct extends { id?: string }>(productsByServiceGroup: TServiceGroupDto[], promotionConfig: SpaPromotion | null) => {
  
    const getPromotionConfig = (type: PromotionType): TPromotionConfig => {
        const config = (promotionConfig?.SpaPromotion?.[`${type}Promotion`] ?? {}) as TPromotionConfig;
        return {
          startDate: config?.startDate ?? null,
          endDate: config?.endDate ?? null,
          dayTypes: config?.dayTypes ?? null,
          calendarIds: config?.calendarIds ?? null,
          startTime: config?.startTime ?? null,
          endTime: config?.endTime ?? null,
          promotionProductIds: config?.promotionProductIds ?? null,
          promotionDescription: config?.promotionDescription ?? null,
          promotionTimeslotDescription: config?.promotionTimeslotDescription ?? null,
          promotionTimeslotDiscountPercentage: config?.promotionTimeslotDiscountPercentage ?? 0,
        };
      };
    const getProductIdsByStation = (stationName: string, options?: { excludeId?: string }) => {
      const products = productsByServiceGroup?.filter(el => el.name == stationName)?.flatMap(el => el.products?.map(el => el.id)) ?? [];
      return options?.excludeId ? products.filter(id => id !== options.excludeId) : products;
    };

    const promoConfigs: Record<PromotionTypeValue, TPromotionConfig> = useMemo(() => ({
      [PromotionType.Espa]: getPromotionConfig(PromotionType.Espa),
      [PromotionType.Kbs]: getPromotionConfig(PromotionType.Kbs),
      [PromotionType.Acu]: getPromotionConfig(PromotionType.Acu),
    }), [promotionConfig]);  

    const productIds = useMemo(() => ({
        [PromotionType.Espa]: promoConfigs[PromotionType.Espa]?.promotionProductIds ?? getProductIdsByStation('ESPA'),
        [PromotionType.Kbs]: promoConfigs[PromotionType.Kbs]?.promotionProductIds ?? getProductIdsByStation('KBS'),
        [PromotionType.Acu]: promoConfigs[PromotionType.Acu]?.promotionProductIds ?? getProductIdsByStation('Acupressure'),
    }), [promotionConfig, productsByServiceGroup]);

    const hasAnyPromoConfig = Object.values(promoConfigs).some(config => config?.startDate && config?.endDate);
  
    const getPromotionTypeByProductId = (productId: string): PromotionType => PROMOTION_TYPES.find(type => productIds[type]?.includes(productId));
    const getDayOfWeek = (utcString: string): number => getDay(utcToZonedTime(utcString, timeZone));

    const isValidPromoDate = (type: PromotionType, calendarId: string, utcString: string, productId: string): boolean => {
      const config = promoConfigs[type];
      if (!config?.startDate || !config?.endDate || !utcString) return false;
  
      const isValidCalendarId = config.calendarIds?.includes(calendarId);
      const isValidDayType = config.dayTypes?.includes(getDayOfWeek(utcString));
      const isValidProduct = productIds[type]?.includes(productId);
      
      return isValidProduct && (isValidCalendarId || isValidDayType) && utcString?.isWithinIntervalByUtcString(config.startDate, config.endDate);
    };
    const isValidPromoTimeslot = (type: PromotionType, calendarId: string, utcString: string, productId: string, time: string): boolean => {
      const config = promoConfigs[type];
      if(!config?.startTime || !config?.endTime || !time) return false;
      return isValidPromoDate(type, calendarId, utcString, productId) && isTimeWithinInterval(time, config.startTime, config.endTime);
    };
  
    const isPromotionDateslot = (calendarId: string, utcString: string, productId: string): boolean => {
      if (!hasAnyPromoConfig) return false;
      return PROMOTION_TYPES.some(type => isValidPromoDate(type, calendarId, utcString, productId));
    };
  
    const isPromotionTimeslot = (calendarId: string, utcString: string, productId: string, timeslotTime: string): boolean => {
      if (!hasAnyPromoConfig) return false;
      return isPromotionDateslot(calendarId, utcString, productId) && PROMOTION_TYPES.some(type => isValidPromoTimeslot(type, calendarId, utcString, productId, timeslotTime));
    };
  
    const getPromotionDescription = (type: PromotionType): string => {
      const config = promoConfigs[type];
      if (config?.promotionDescription) return config.promotionDescription;
      return null;
    };
  
    const getPromotionTimeslotDescription = (type: PromotionType): string => {
      const config = promoConfigs[type];
      if (config?.promotionTimeslotDescription) return config.promotionTimeslotDescription;
      return null;
    };
  
    const getPromotionTimeslotDiscountPercentage = (type: PromotionType): number => {
      const config = promoConfigs[type];
      if (config?.promotionTimeslotDiscountPercentage) return config.promotionTimeslotDiscountPercentage;
      return null;
    };
  
    return {
      getConfig: (type: PromotionType) => promoConfigs[type],
      getPromotionTypeByProductId,
  
      isEspaPromoDate: (calendarId: string, utcString: string, productId: string) => isValidPromoDate(PromotionType.Espa, calendarId, utcString, productId),
      isKbsPromoDate: (calendarId: string, utcString: string, productId: string) => isValidPromoDate(PromotionType.Kbs, calendarId, utcString, productId),
      isAcuPromoDate: (calendarId: string, utcString: string, productId: string) => isValidPromoDate(PromotionType.Acu, calendarId, utcString, productId),
  
      isPromoTypeDate: (type: PromotionType, calendarId: string, utcString: string, productId: string) => isValidPromoDate(type, calendarId, utcString, productId),
      isPromotionDateslot,
  
      isPromoTypeTimeslot: (type: PromotionType, calendarId: string, utcString: string, productId: string, time: string) => isValidPromoTimeslot(type, calendarId, utcString, productId, time),
      isPromotionTimeslot,
  
      getPromotionTimeslotDiscountPercentage,
      getPromotionTimeslotDescription,
      getPromotionDescription,
    };
  };
  
  function isTimeWithinInterval(time: string, startTime: string, endTime: string): boolean {
    const zonedTimeByTimeslotTime = utcToZonedTime(time, timeZone);
    const currentTime = format(zonedTimeByTimeslotTime, 'HH:mm');
  
    const parseTime = (timeStr: string) => parse(timeStr, 'HH:mm', zonedTimeByTimeslotTime);
    const setDayToSameDay = (time: Date, timeslotDate: Date) => set(time, { year: timeslotDate.getFullYear(), month: timeslotDate.getMonth(), date: timeslotDate.getDate() });
  
    const parsedCurrentTime = parseTime(currentTime);
    const parsedStartTime = parseTime(startTime);
    const parsedEndTime = parseTime(endTime);
  
    const normalizedCurrent = setDayToSameDay(parsedCurrentTime, zonedTimeByTimeslotTime);
    const normalizedStart = setDayToSameDay(parsedStartTime, zonedTimeByTimeslotTime);
    const normalizedEnd = setDayToSameDay(parsedEndTime, zonedTimeByTimeslotTime);
  
    return isWithinInterval(normalizedCurrent, {
      start: normalizedStart,
      end: normalizedEnd,
    });
  }
  