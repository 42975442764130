import { Calendar } from './calendar';
import { Station } from './station';
import { ServiceTimeslots } from './service_timeslots';

export class BusinessScheduleRelations {
  Calendar: Calendar;

  Station: Station;

  ServiceTimeslots: ServiceTimeslots[];
}
