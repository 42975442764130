import { Product } from './product';

export class TaxRelations {
  Products1: Product[];

  Products2: Product[];

  Products3: Product[];

  Products4: Product[];
}
