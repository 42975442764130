export class CategoryOnline {
  id: string;

  name: string;

  slug: string;

  sequence: number;

  visibility: number;

  imageUrl?: string;

  parentId?: string;

  meta?: object;

  headerHtml?: string;

  publishedAt: Date;

  status: number;

  categoryId?: string;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
