export { PrismaModel as schema } from './schema';
export * as id from './id';
export * as queue from './queue';
export * as admission from './reservation/admission';
export * as treatment from './reservation/treatment';
export * as cart from './cart';
export * from './espa';
export * as googleAnalytics from './google-analytics';

export type VoidResponse = {
  code?: number;
  messages?: string[];
};

export enum PatchOperation {
  add = 'add',
  replace = 'replace',
  test = 'test',
  remove = 'remove',
  move = 'move',
  copy = 'copy',
}

export type Patch = {
  op: PatchOperation;
  /** Path */
  path: string;
  /** Value */
  value?: object;
  /** From */
  from?: string;
};

export enum ProductOnlineVisibility {
  None = 0,
  Shop = 1,
  BackOffice = 2,
  All = 3,
}
export enum OrderType {
  Unknown = 0,
  DailyAdmissionReservation = 1,
  GiftAndCertificate = 2,
  TreatmentReservation = 3,
  All = 99,
}

export enum TenderType {
  Unknown = 0,
  Paypal = 1,
  NZGiftcard = 2,
  Coupon = 3,
  Invoice = 4,
  Payflow = 11,
  Braintree = 12,
  BraintreeVault = 13,
  POSReconciliation = 14,
  Skip = 15,
  NoTender = 16,
  Declined = 17,
  Waived = 18,
}

export enum PaymentType {
  Auth = 1,
  Capture = 2,
  Sale = 4,
  Reversal = 5,
  Void = 6,
  Refund = 7,
  Vault = 11,
}

export enum OrderStatus {
  Deleted = 0,
  Active = 1,
  Processing = 2,
  Failed = 9,
  Closed = 11,
  Void = 21,
  Voided = 22,
  Refund = 23,
  Refunded = 24,
  Cancelled = 31,
}

export enum OrderLineStatus {
  Deleted = 0,
  Active = 1,
  Processing = 2,
  Inactive = 3,
  Failed = 9,
  Void = 21,
  Voided = 22,
  Refund = 23,
  Refunded = 24,
  Cancelled = 31,
}

export enum ReservationStatus {
  Deleted = 0,
  Active = 1,
  Held = 2,
  Released = 3,
  Cancelled = 4,
  NoShowed = 5,
}

export enum TicketStatus {
  Deleted = 0,
  Active = 1,
  Held = 2,
  Released = 3,
  Cancelled = 4,
  NoShowed = 5,
}

export enum PaymentStatus {
  NotSet = 0,
  Processing = 1,
  Succeed = 2,
  Verified = 3,
  Validated = 4,
  //Skipped = 5,
  Waived = 6,

  VoidProcessing = 11,
  Voided = 12,
  Removed = 13,

  RefundProcessing = 21,
  Refunded = 22,
  RefundQueued = 23,

  Failed = 9,
}

export enum ChannelStatus {
  Processing = 1,
  Completed = 2,
  Failed = 3,
  CancelProcessing = 11,
  CancelCompleted = 22,
  CancelFailed = 33,
}

export enum DeliveryMethod {
  Digital = 1,
  Shipping = 2,
  Pickup = 3,
}

export type CreditCard = {
  cardNumber: string;
  expiry: string;
  cvc: string;
  cardHolderName: string;
};

export enum HistoryLevel {
  Trace = 1,
  Debug = 2,
  Info = 3,
  Warn = 4,
  Error = 5,
}

export enum NoteLevel {
  Guest = 1,
  Employee = 2,
}

export enum Status {
  All = 0,
  Active = 1,
  Inactive = 2,
}

export enum AppointmentStatus {
  All = 0,
  Active = 1,
  Inactive = 2,
  Held = 3,
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum SortBy {
  Name = 'name',
  FullName = 'fullName',
  UpdatedAt = 'updatedAt',
  Path = 'path',
  Sequence = 'sequence',
}

export enum ProductServiceType {
  Service = 1,
  CouplesService = 2,
  VSB = 3,
  Halo = 4,
  AddOn = 5,
}

export enum ProductServiceMapType {
  Therapist = 1,
  Room = 2,
  GiftCertificate = 3,
}

export enum DayNameType {
  Sunday = 1,
  Monday = 2,
  Tuesday = 3,
  Wednesday = 4,
  Thursday = 5,
  Friday = 6,
  Saturday = 7,
}

export enum SortType {
  All = 'all',
}

export enum RecordType {
  Department = 1,
  Role = 2,
  Employee = 4,
  Category = 8,
  Product = 16,
}
export enum TablePrefix {
  category = 'CT',
  department = 'DP',
  employee = 'EP',
  employeeSchedule = 'ES',
  identity = 'ID',
  profile = 'PF',
  product = 'PD',
  productServiceMap = 'PS',
  recursiveCategory = 'RC',
  role = 'RO',
  room = 'RM',
  tax = 'TX',
  therapistSchedule = 'TS',
  therapistOrder = 'TO',
  location = 'LC',
  station = 'ST',
  businessSchedule = 'BS',
  appointment = 'AP',
  onlineCategory = 'OC',
  onlineProduct = 'OP',
  onlineProductMap = 'OM',
  onlineTags = 'OT',
  campaign = 'CA',
  coupon = 'CP',
  couponRestriction = 'CR',
}

export enum CategoryType {
  Service = 1,
  Retail = 2,
}

export enum ServiceGroup {
  All = 'all',
  ESPA = 'espa',
  KBS = 'kbs',
  ACUPRESSURE = 'acupressure',
}

export enum ServiceGroupId {
  Espa = 'SG_ESPA_ESPA__',
  KBSMen = 'SG_KBS_MKBS___',
  KBSWomen = 'SG_KBS_WKBS___',
  Acupressure = 'SG_ACCU_ACCU__',
}

export enum StationIds {
  ESPA = 'ST14W4ZEU0J0QJ',
  KBS = 'ST14WGAWFE33VB',
  Acupressure = 'ST15A8IA9IMAKP',
}

export enum EmployeeGender {
  Female = 'F',
  Male = 'M',
}

export enum AppointmentType {
  Package = 1,
  Service = 2,
  Block = 3,
  Break = 4,
  Lunch = 5,
  DayOff = 6,
  TimeOff = 7,
}

export enum AppointmentFrom {
  Shop = 1,
  AdvancedBooking = 2,
  Scheduler = 3,
}

export enum ResourceType {
  ByStaffs = 1,
  ByTherapist = 2,
}

export enum Visibility {
  Hidden = 0, // hidden in shop & backoffice
  Shop = 1, // visible in shop
  Backoffice = 2, // visible in backoffice
  All = 3, // visible in shop & backoffice
  ShopList = 4, // visible in shop as a list item
  ShopDetail = 5, // visible in shop detail as a checkbox option
  ShopListAndBackoffice = 6, // ShopList + Backoffice (4 + 2)
  ShopDetailAndBackoffice = 7, // ShopCheckbox in detail + Backoffice (5 + 2)
}

export enum OnlineProductMapType {
  FrequentlyAdded = 1,
  OnlineCategory = 2,
}

export enum OnlineTagType {
  OnlineProduct = 1,
  OnlineCategory = 2,
}

export enum NZDeliveryMethod {
  ShippingAddressDifferent = 1,
  SameAsBillingAddress = 2,
  PickUp = 3,
  Email = 4,
}

export enum RateType {
  Tax = 1,
  Tip = 2,
}

export enum DecimalType {
  Fixed = 1,
  Percent = 2,
}

export enum TreatmentNotificationType {
  ReservationComplete = 1,
  ReservationConfirmation = 2,
  ReservationUpdate = 3,
  ReservationCancellation = 4,
  ReservationRefund = 5,
  ReservationSurvey = 6,
  ReservationMedicalNoteNotification = 7,
}

export enum RemindingType {
  None = 0,
  Email = 1,
  Text = 2,
  EmailAndText = 3,
}

export enum TimeslotType {
  Backoffice = 1,
  Shop = 2,
  All = 3,
}

export enum PreferenceGender {
  NotSet = -1,
  Any = 0,
  Male = 1,
  Female = 2,
  Lock = 3,
  Request = 4,
}

export enum BookingStatus {
  NotSet = -1,
  Held = 0,
  Booked = 1,
  ConfirmedByGuest = 11,
  ConfirmedByUser = 12,
  UndoConfirmed = 13,
  BuildingCheckedIn = 20,
  UndoBuildingCheckedIn = 21,
  ServiceCheckedIn = 30,
  UndoServiceCheckedIn = 31,
  ServiceCheckedOut = 40,
  BuildingCheckedOut = 50,
  CancelledAt = 60,
}

export enum RestrictionType {
  NotSet = 0,
  Watching = 1,
  Banned = 2,
}

export enum OrderFromType {
  Shop = 1,
  Backoffice = 2,
  Scheduler = 3,
}

export enum NZProductType {
  Coupon = 'Coupon',
  Giftcard = 'Giftcard',
  Service = 'Service',
  Retail = 'Retail',
}

export enum CancelledListType {
  All = 0,
  SameDay = 1,
}

export enum SettingType {
  Number = 1,
  Date = 12,
  Date_ISO8601 = 11,
  JSON = 21,
}

export enum StationUrl {
  ESPA = 'espa/schedule',
  KBS = 'kbs/schedule',
  Acupressure = 'acupressure/schedule',
  All = 'all/schedule',
}

export enum ProductType {
  DailyAdmission = 1,
  GiftAndCertificate = 2,
  SpaTreatment = 3,
  SpaRetail = 4,
}

export enum CustomerRestrictionExportType {
  SHOP = 1,
  SPA = 2,
}

export enum ScheduleViewType {
  All = 0,
  Active = 1,
  Vacation = 2,
}

export enum FBEvent {
  ViewContent = 'ViewContent',
  AddToCart = 'AddToCart',
  InitiateCheckout = 'InitiateCheckout',
  AddPaymentInfo = 'AddPaymentInfo',
  Purchase = 'Purchase',
}

export enum CampaignType {
  FreeShipping = 1,
  Discount = 2,
  BOGO = 3,
}

export enum CampaignRestrictionType {
  None = 1,
  Email = 2,
  Phone = 3,
}

export enum RuleEngineEventType {
  applyAddedValue = 'applyAddedValue',
  applyFreeShipping = 'applyFreeShipping',
  applyKBS20 = 'applyKBS20%',
  applyESPAFreeDA = 'applyESPAFreeDA',
  applyACU20 = 'applyACU20%',
}

export enum CouponType {
  PredefinedCouponWithEmailValidation = 1,
  RandomCouponWithEmailValidation = 2,
}
