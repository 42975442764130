export class SearchKeyword {
  id: number;

  relationId?: string;

  type: number;

  keyword: string;

  sourceId?: string;

  source: string;
}
