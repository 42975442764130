import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = [
  "getEcho",
  "getCoupons",
  "postCoupon",
  "getCampaigns",
  "createCampaign",
  "getCampaign",
  "validateCampaign",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getEcho: build.query<GetEchoApiResponse, GetEchoApiArg>({
        query: () => ({ url: `/campaign/echo` }),
        providesTags: ["getEcho"],
      }),
      getCoupons: build.query<GetCouponsApiResponse, GetCouponsApiArg>({
        query: (queryArg) => ({ url: `/campaign/coupon` }),
        providesTags: ["getCoupons"],
      }),
      postCoupon: build.mutation<PostCouponApiResponse, PostCouponApiArg>({
        query: () => ({ url: `/campaign/coupon`, method: "POST" }),
        invalidatesTags: ["postCoupon"],
      }),
      getCampaigns: build.query<GetCampaignsApiResponse, GetCampaignsApiArg>({
        query: (queryArg) => ({
          url: `/campaign`,
          params: {
            sortDirection: queryArg.sortDirection,
            keyword: queryArg.keyword,
            sortBy: queryArg.sortBy,
            status: queryArg.status,
          },
        }),
        providesTags: ["getCampaigns"],
      }),
      createCampaign: build.mutation<
        CreateCampaignApiResponse,
        CreateCampaignApiArg
      >({
        query: (queryArg) => ({
          url: `/campaign`,
          method: "POST",
          data: queryArg.createCampaignRequest,
        }),
        invalidatesTags: ["createCampaign"],
      }),
      getCampaign: build.query<GetCampaignApiResponse, GetCampaignApiArg>({
        query: (queryArg) => ({ url: `/campaign/${queryArg.id}` }),
        providesTags: ["getCampaign"],
      }),
      validateCampaign: build.mutation<
        ValidateCampaignApiResponse,
        ValidateCampaignApiArg
      >({
        query: (queryArg) => ({
          url: `/campaign/validate`,
          method: "POST",
          data: queryArg.validationRequest,
        }),
        invalidatesTags: ["validateCampaign"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeCampaignApi };
export type GetEchoApiResponse = unknown;
export type GetEchoApiArg = void;
export type GetCouponsApiResponse = /** status 200  */ CouponsResponse;
export type GetCouponsApiArg = {
  ids: any;
};
export type PostCouponApiResponse = unknown;
export type PostCouponApiArg = void;
export type GetCampaignsApiResponse = /** status 200  */ CampaignsResponse;
export type GetCampaignsApiArg = {
  /** asc or desc */
  sortDirection?: string;
  keyword?: string;
  sortBy?: SortBy;
  status?: Status;
  slug: string;
};
export type CreateCampaignApiResponse = /** status 200  */ CampaignResponse;
export type CreateCampaignApiArg = {
  createCampaignRequest: CreateCampaignRequest;
};
export type GetCampaignApiResponse = /** status 200  */ CampaignResponse;
export type GetCampaignApiArg = {
  id: string;
};
export type ValidateCampaignApiResponse =
  /** status 200  */ CampaignValidationResponse;
export type ValidateCampaignApiArg = {
  validationRequest: ValidationRequest;
};
export type DayNameType = 1 | 2 | 3 | 4 | 5 | 6 | 7;
export type DecimalType = 1 | 2;
export type EventResult = {
  dayTypes?: DayNameType[];
  rateType?: DecimalType;
  rate?: number;
  conditionPrice?: number;
  description?: string;
  timeslotDescription?: string;
  startTime?: string;
  endTime?: string;
};
export type RuleEvent = {
  type: string;
  params: EventResult;
};
export type BaseRule = {
  event: RuleEvent;
  name: string;
};
export type CampaignDto = {
  id: string;
  type: number;
  name: string;
  startAt: string;
  endAt: string;
  description?: string;
  rules?: BaseRule[];
};
export type CouponRestriction = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  couponId: string;
  keyword: string;
  status: number;
};
export type Coupon = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  campaignId: string;
  code: string;
  restrictionType: number;
  redemptionLimit: number;
  redeemed: number;
  status: number;
  issuedAt: string;
  expireAfterIssuedMinutes: number;
  meta: object;
  Campaign?: CampaignDto;
  CouponRestriction: CouponRestriction[];
};
export type CouponsResponse = {
  code?: number;
  messages?: string[];
  result?: Coupon[];
};
export type CampaignsResponse = {
  code?: number;
  messages?: string[];
  result?: CampaignDto[];
};
export type SortBy = "name" | "fullName" | "updatedAt" | "path" | "sequence";
export type Status = 0 | 1 | 2;
export type Campaign = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  type: number;
  name: string;
  startAt: string;
  endAt: string;
  description?: string;
  rules?: BaseRule[];
  status: number;
  meta?: object;
  Coupon: Coupon[];
};
export type CampaignResponse = {
  code?: number;
  messages?: string[];
  result?: Campaign;
};
export type CouponType = 1 | 2;
export type CouponRequest = {
  restrictionType: number;
  redemptionLimit: number;
  expireAfterIssuedMinutes: number;
  meta: object;
  couponType?: CouponType;
  couponCode?: string;
  emails?: string[];
  phone?: string;
  couponLength?: number;
  customCharSets?: string[];
  prefix?: string;
  suffix?: string;
  campaignId?: string;
};
export type CreateCampaignRequest = {
  type: number;
  name: string;
  startAt: string;
  endAt: string;
  description?: string;
  rules?: BaseRule[];
  meta?: object;
  Coupon?: CouponRequest | null;
};
export type EventDto = {
  type: string;
  params: EventResult;
};
export type CampaignValidationResponse = {
  code?: number;
  messages?: string[];
  result?: EventDto[];
};
export type CouponValidationRequest = {
  couponId: string;
  orderId: string;
};
export type Facts = {
  coupon?: CouponValidationRequest;
  date?: string;
  amount?: number;
};
export type ValidationRequest = {
  facts: Facts;
};
export const {
  useGetEchoQuery,
  useGetCouponsQuery,
  usePostCouponMutation,
  useGetCampaignsQuery,
  useCreateCampaignMutation,
  useGetCampaignQuery,
  useValidateCampaignMutation,
} = injectedRtkApi;
