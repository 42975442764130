export class Customer {
  id: string;

  profileId: string;

  isGuest: boolean;

  phoneNumber1?: string;

  phoneNumber2?: string;

  phoneNumber3?: string;

  membershipNumber?: string;

  membershipType?: number;

  membershipValidFrom?: Date;

  membershipValidUntil?: Date;

  isOptOutMarketing: boolean;

  isOptOutReminding?: number;

  restrictionType: number;

  vaultCode?: string;

  legacyId?: string;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
