import { Box, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

export const GrayBox = (props: { overrideGrayBoxSx?: SxProps; children?: ReactNode }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px 0', gap: '16px', alignSelf: 'stretch', ...props.overrideGrayBoxSx }}>
      <Box sx={{ display: 'flex', padding: '24px', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: '8px', flex: '1 0 0', background: '#F5F5F5' }}>{props.children}</Box>
    </Box>
  );
};
export const NoticeOfKBSAndAcupressure = (props: { overrideGrayBoxSx?: SxProps }) => {
  return (
    <GrayBox overrideGrayBoxSx={props.overrideGrayBoxSx}>
      <Typography component="span" sx={{ color: '#333', fontFamily: 'Inter', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight: '150%' /* 21px */ }}>
        Korean Body Scrubs & Acupressure
        <Typography component="span" display={'inline'} sx={{ color: 'var(--Primary-Dark, #333)', fontFamily: 'Inter', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight: '150%' }}>
          {' '}
          require the purchase of a daily admission pass upon arrival. You may arrive anytime during the daily admission hours, but no later than 60 minute before your service.
        </Typography>
      </Typography>
    </GrayBox>
  );
};
