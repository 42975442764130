import { ApiKey } from './api_key';
import { GrantedPermission } from './granted_permission';
import { Department } from './department';
import { Employee } from './employee';
import { StationMap } from './station_map';
import { ProductsByServiceGroup } from './products_by_service_group';

export class RoleRelations {
  ApiKeys: ApiKey[];

  GrantedPermissions: GrantedPermission[];

  Department?: Department;

  Employees: Employee[];

  StationMaps: StationMap[];

  ProductsByServiceGroups: ProductsByServiceGroup[];
}
