export class TherapistOrder {
  id: string;

  calendarId: string;

  employeeId: string;

  rank: number;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
