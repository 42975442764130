import { Customer } from './customer';
import { Employee } from './employee';
import { Identity } from './identity';
import { History } from './history';
import { Note } from './note';
import { CancelledOrderLines } from './cancelled_order_lines';

export class ProfileRelations {
  Customer?: Customer;

  Employee?: Employee;

  Identity?: Identity;

  Histories: History[];

  Notes: Note[];

  CancelledOrderLines: CancelledOrderLines[];
}
