import { Order } from './order';
import { OrderShipping } from './order_shipping';
import { Product } from './product';
import { Ticket } from './ticket';
import { Employee } from './employee';
import { Appointment } from './appointment';
import { CancelledOrderLines } from './cancelled_order_lines';
import { RefundedOrderLines } from './refunded_order_lines';

export class OrderLinesRelations {
  Order: Order;

  OrderShipping?: OrderShipping;

  Product: Product;

  Ticket?: Ticket;

  Employee?: Employee;

  Appointment?: Appointment;

  CancelledOrderLine?: CancelledOrderLines;

  RefundedOrderLines: RefundedOrderLines[];
}
