export class Images {
  id: string;

  relationId: string;

  filename: string;

  blob: Buffer;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
