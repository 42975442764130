import { RecursiveCategory } from './recursive_category';
import { ProductPriceByTimeslot } from './product_price_by_timeslot';
import { OrderLines } from './order_lines';
import { ProductService } from './product_service';
import { Tax } from './tax';
import { ProductOnline } from './product_online';
import { ProductsByServiceGroup } from './products_by_service_group';
import { ProductServiceMap } from './product_service_map';

export class ProductRelations {
  RecursiveCategory?: RecursiveCategory;

  ProductPriceByTimeslots: ProductPriceByTimeslot[];

  OrderLines: OrderLines[];

  ProductService?: ProductService;

  Tax1?: Tax;

  Tax2?: Tax;

  Tax3?: Tax;

  Tip?: Tax;

  ProductOnline?: ProductOnline;

  ProductsByServiceGroups: ProductsByServiceGroup[];

  ProductServiceMap: ProductServiceMap[];
}
