import { assert } from './error'

export function noop(): void {
}

export function whenNextTick() {
    return new Promise((resolve) => process.nextTick(resolve))
}

export function delay(minDelayMilliseconds: number = 0) {
    return new Promise((resolve) => setTimeout(resolve, minDelayMilliseconds))
}

export const whenEvent = (event: string) => (emitter: {
    once: (event: string, callback: () => void) => void
}) => new Promise<void>((resolve) => emitter.once(event, resolve))

export const whenData = whenEvent('data')
export const whenClose = whenEvent('close')
export const whenError = whenEvent('error')

export function randomInteger(
    minInclusive: number,
    maxExclusive: number,
): number {
    assert(
        Number.isSafeInteger(minInclusive),
        'Argument "minInclusive" must be a safe integer.',
    )
    assert(
        Number.isSafeInteger(maxExclusive),
        'Argument "maxExclusive" must be a safe integer.',
    )
    assert(
        minInclusive <= maxExclusive,
        'Argument "minInclusive" must be less or equal to argument "maxExclusive".',
    )

    return Math.floor(
        minInclusive + Math.random() * (maxExclusive - minInclusive),
    )
}

export function combine(...parts: string[]): string {
    return parts.map(combineEscape).join('~')
}

export function separate(value: string): string[] {
    const result = []
    const regex = /(?:![!~]|[^~])*/y
    let match
    // tslint:disable-next-line:no-conditional-assignment
    while ((match = regex.exec(value))) {
        result.push(separateUnescape(match[0]))
        regex.lastIndex++
    }
    return result
}

function combineEscape(value: string): string {
    return value.replace(/[!~]/g, '!$&')
}

function separateUnescape(value: string): string {
    return value.replace(/!(.)/g, '$1')
}

export function first<T>(array: T[]): T | undefined {
    return array[0]
}

export function last<T>(array: T[]): T | undefined {
    return array[array.length - 1]
}

