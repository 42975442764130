export class CouponRestriction {
  id: string;

  couponId: string;

  keyword: string;

  status: number;

  createdBy: string;

  createdAt: Date;

  updatedBy: string;

  updatedAt: Date;
}
